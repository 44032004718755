import React from 'react';
import { Accordion, Card, Button, Table } from 'react-bootstrap'
import moment from 'moment';
import DayPicker from 'react-day-picker';
import { timeToInt } from 'time-number';
import { Auth, API } from "aws-amplify";
import './Appointment.css';
export default class AttendeeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: "",
            APIDateStudentResults: [],
            unslotedMembers: [],
            selectedDay: [],
            selectedDays: [],
            activeKey: '0',
            selectedDaysRange: "",
            dataDirty: false
        };
        this.onDateChange = this.onDateChange.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.sortSlots = this.sortSlots.bind(this)
    }

    handleGetSlotsbyDay = async (day) => {
        let dateNeeded = moment(day).format("YYYY-MM-DD")
        this.setState({ loadingDay: true })
        const that = this;

        let session = await Auth.currentSession();
        let options = {
            body: { "date": dateNeeded },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        }
        try {
            let data = await API.post("APIGateway", "/v2getStudentsByFilter", options)
            data = this.sortSlots(data, "slot");
            that.setState({ loadingDay: false, fetchedStudents: true })
            return data

        }
        catch (e) {
            console.log(e)
            return ([])
        }
    }
    async handleDayClick(date, { selected }) {
        let selectedDaysRange = ""

        let dayOfWeek = date.getDay()
        let selectedDays = []
        if (dayOfWeek === 0) { //They picked a Sunday, but we really want the start of the weekend
            selectedDays.push(moment(date).subtract(1, 'd').toDate())
            selectedDays.push(moment(date).toDate())
        }
        else if (dayOfWeek === 6) { //They picked a Saturday, but we really want the start of the weekend
            selectedDays.push(moment(date).toDate())
            selectedDays.push(moment(date).add(1, 'd').toDate())
        }
        else {
            //Only 1-5 now.
            selectedDays = [date]
        }
        this.setState({ selectedDays })

        let selectedDay = selectedDays[0]
        selectedDaysRange += moment(selectedDay).format('LL') + " - "

        let APIDateStudentResults = await this.handleGetSlotsbyDay(selectedDay)
        //We need to figure out if there is an already selected Weekday from all of the students that were fetched.
        if (APIDateStudentResults.length > 0) {
            var selectedWeekenedDates = new Set(APIDateStudentResults.filter(x => (x.weekendDate)).map(x => x.weekendDate))
            selectedWeekenedDates = [...selectedWeekenedDates]
            if (selectedWeekenedDates.length > 0) {//Only one date, let's assume it's that one and move on.
                var selectedWeekday = selectedWeekenedDates[0]
                var weekArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
                var weekendArray = ["Saturday", "Sunday"]
                var preSlottedFolks = APIDateStudentResults.filter(x => x.slot)
                if (preSlottedFolks.length > 0) {
                    var slots = preSlottedFolks.map(function (x) { return { slotName: x.slot, person: x.fName + " " + x.lName, value: x } })
                    this.setState({ slots: this.sortSlots(slots, 'slotName') })
                }
                if (dayOfWeek === 6) {
                    this.setState({ dayOfExam: selectedDays[weekendArray.indexOf(selectedWeekday)], selectedWeekday })
                }
                else {
                    this.setState({ dayOfExam: selectedDays[weekArray.indexOf(selectedWeekday)], selectedWeekday })
                }
                this.setState({ unslotedMembers: APIDateStudentResults.filter(x => !x.slot) })
            }
            //No day of the week found. Move the user to select the day of week
            else {
                //this.setState({ activeKey: "confirmWeekdayDate" })
            }
        }
        selectedDaysRange += moment(selectedDays[selectedDays.length - 1]).format('LL')
        //The selected date is the first entry of the selectedDays
        this.setState({
            selectedDay, APIDateStudentResults, selectedDaysRange, unsavedManuallyAddedStudents: [], dataDirty: true
        });
    }

    handleErrors = (response) => {
        if (!response.ok) {
            console.log(response.statusText);
        }
        return response;
    }
    sortSlots(array, key) {
        if (array.length === 0) {
            return []
        }
        return array.sort(function (a, b) {
            var x = 0;
            var y = 0;
            if (a.hasOwnProperty(key)) {
                x = timeToInt(a[key]);
            }
            if (b.hasOwnProperty(key)) {
                y = timeToInt(b[key])
            }
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    onDateChange(e) {
        this.setState({
            selectedDate: e.currentTarget.value
        });
    }
    handleSelect(activeKey) {
        this.setState({ activeKey });
    }

    render() {
        const { activeKey, selectedDays, selectedDaysRange, APIDateStudentResults } = this.state;

        return (
            <div className="holder">

                <h3>Print Road Test Schedule</h3>

                Please select the time period you want to print a schedule of.
                <Accordion defaultActiveKey="0" activeKey={activeKey}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" onClick={() => this.setState({ activeKey: "0" })}>
                            {selectedDaysRange.length > 0 ? <div>
                                {selectedDaysRange}
                            </div> : "Select week"}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <DayPicker
                                    numberOfMonths={2}
                                    selectedDays={selectedDays}
                                    showOutsideDays
                                    onDayClick={this.handleDayClick}
                                />
                                <br></br>
                                <Button disabled={this.state.loadingDay || this.state.selectedDay.length === 0} className="butPadding" onClick={() => this.setState({ activeKey: "1" })}>{this.state.loadingDay ? "Please wait. Loading students..." : "View Roster"}</Button>
                                {this.state.dataDirty ?
                                    <div>
                                        {APIDateStudentResults.length > 0 ? <div>Found {APIDateStudentResults.length} students</div> : <div>No students found.</div>}
                                    </div>
                                    : null}

                            </Card.Body>
                        </Accordion.Collapse>

                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" onClick={() => this.setState({ activeKey: "1" })}>
                            Roster
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <h4>West Roxbury</h4>
                                {APIDateStudentResults.filter(x => x.location === "West Roxbury").length > 0 ?
                                    <div>
                                        <Table striped bordered hover responsive size="sm">
                                            <thead>
                                                <tr className="d-flex">
                                                    <th className="col-2">Slot</th>
                                                    <th className="col-5">Name</th>
                                                    <th className="col-5">Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {APIDateStudentResults.filter(x => x.location === "West Roxbury").map((x, index) => {
                                                    return (
                                                        <tr key={index} className="d-flex">
                                                            <td className="col-2">{x.slot}</td>
                                                            <td className="col-5">{x.fName + " " + x.lName}</td>
                                                            <td className="col-5">{x.note}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                        <br></br>
                                    </div>

                                    :
                                    <div>
                                        <br></br>
                                        There are no students for the selected time.</div>
                                }
                                <h4>Weston</h4>


                                {APIDateStudentResults.filter(x => x.location === "Walpole").length > 0 ?
                                    <div>
                                        <Table striped bordered hover responsive size="sm">
                                            <thead>
                                                <tr className="d-flex">
                                                    <th className="col-2">Slot</th>
                                                    <th className="col-5">Name</th>
                                                    <th className="col-5">Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {APIDateStudentResults.filter(x => x.location === "Walpole").map((x, index) => {
                                                    return (
                                                        <tr key={index} className="d-flex">
                                                            <td className="col-2">{x.slot}</td>
                                                            <td className="col-5">{x.fName + " " + x.lName}</td>
                                                            <td className="col-5">{x.note}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                        <br></br>
                                    </div>

                                    :
                                    <div>
                                        <br></br>
                                        There are no students for the selected time.
                                    </div>
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    }
}
