import React from 'react';
import { Formik, Field } from "formik";
import { Button, Table, Alert } from 'react-bootstrap'
import { Auth, API } from "aws-amplify";
import classnames from 'classnames';
import crypto from 'crypto-js';

import './SetRoadTestInstructors.css';

// Radio input
const RadioButton = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    className,
    ...props
}) => {
    return (
        <div>
            <input
                name={name}
                id={id}
                type="radio"
                value={id} // could be something else for output?
                checked={id === value}
                onChange={onChange}
                onBlur={onBlur}
                className={classnames("radio-button")}
                {...props}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

const InputFeedback = ({ error }) =>
    error ? <div className="input-feedback">{error}</div> : null;

const RadioButtonGroup = ({
    value,
    error,
    touched,
    id,
    label,
    className,
    children
}) => {
    const classes = classnames(
        "input-field",
        {
            "is-success": value || (!error && touched), // handle prefilled or user-filled
            "is-error": !!error
        },
        className
    );
    return (
        <div className={classes}>
            <fieldset>
                <legend>{label}</legend>
                {children}
                {touched && <InputFeedback error={error} />}
            </fieldset>
        </div>
    );
};
export default class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
        this.state = {
            packages: [],
            instructors: [],
            loading: false,
            values: {},
            errors: {},
            touched: {},
            password: "ef92b778bafe771e89245b89ecbc08a44a4e166c06659911881f383d4473e94f"
        };
    }


    fetchRoadTestInstructors() {
        const that = this;
        fetch('https://s3.amazonaws.com/publicdates/roadTestInstructors.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                if (typeof (data) === "undefined") {
                    that.setState({ instructors: [] })
                }
                else {
                    var decryptedInstructors = []
                    for (let i = 0; i < data.length; i++) {
                        let instructor = data[i];
                        var decryptedName = crypto.AES.decrypt(instructor.resourceTitle, that.state.password).toString(crypto.enc.Utf8)
                        decryptedInstructors.push(
                            { resourceId: instructor.resourceId, resourceTitle: decryptedName, value: decryptedName, label: decryptedName }
                        )
                    }
                    that.setState({ instructors: decryptedInstructors })
                }
            });

    }

    componentDidMount() {
        this.fetchRoadTestInstructors();
    }

    onChange(e, index, field) {
        console.log(e, index, field)
        var localInstructors = this.state.instructors
        switch (field) {
            case "resourceTitle":
                localInstructors[index].resourceTitle = e
                break;
            default:
                console.warn("Default value hit. Should not reach here.")
        }
        console.log(localInstructors)
        this.setState({ instructors: localInstructors })
    }
    addNewInstructor = () => {
        var localInstructors = this.state.instructors
        var maxId = Math.max.apply(0, localInstructors.map(x => x.resourceId)) + 1;

        localInstructors.push(
            { resourceId: maxId, resourceTitle: "", value: "", label: "" }
        )
        this.setState({ instructors: localInstructors, refreshStatus: "", packageError: "" })
    }

    deleteInstructor = (index) => {
        var localInstructors = this.state.instructors
        localInstructors.splice(index, 1)
        this.setState({ instructors: localInstructors, refreshStatus: "", packageError: "" })
    }

    handleErrors = (response) => {
        if (!response.ok) {
            this.setState({ loading: false, packageError: "There was an error: " + response.statusText })
        }
        return response;
    }

    handleSubmit = async event => {
        this.setState({ loading: true, refreshStatus: "", packageError: "" })
        let encryptedInstructors = []

        for (let i = 0; i < this.state.instructors.length; i++) {
            let instructor = this.state.instructors[i];
            var encryptedName = crypto.AES.encrypt(instructor.resourceTitle, this.state.password).toString()
            encryptedInstructors.push(
                { resourceId: instructor.resourceId, resourceTitle: encryptedName, value: encryptedName, label: encryptedName }
            )
        }
        const that = this;
        let session = await Auth.currentSession();
        let options = {
            body: encryptedInstructors,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        }
        console.log(encryptedInstructors)
        API.post("APIGateway", "/updateRoadTestInstructors ", options)
            .then(function (data) {
                that.setState({ loading: false, refreshStatus: "Successfully updated!" })
                console.log("Received the following from the API")
                console.log(data)
            }).catch(function (error) {
                console.log(error)
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
    render() {
        return (
            <div className="holder">
                {this.state.packageError ? <Alert variant="warning">
                    <strong>{this.state.packageError}</strong>
                </Alert> : null}
                {this.state.refreshStatus ? <Alert variant="success">
                    <strong>{this.state.refreshStatus}</strong>
                </Alert> : null}
                <div>
                    <h3>Set Active Road Test Instructors</h3>
                    <br></br>
                    <Button variant="success" onClick={this.addNewInstructor}>Add New</Button>
                    <br></br>
                    <br></br>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Instructor Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.instructors ?
                                this.state.instructors.map((x, index) => {
                                    var name = x.resourceTitle;
                                    return (
                                        <tr key={index}>
                                            <td><input className="inputWidth" type='text' onChange={e => this.onChange(e.target.value, index, "resourceTitle")} value={name} /></td>
                                            <td><Button variant="danger" onClick={() => this.deleteInstructor(index)}>Delete</Button></td>
                                        </tr>
                                    )
                                })
                                : null}
                        </tbody>
                    </Table>
                    <Formik
                        ref={this.formikRef}
                        initialValues={{
                        }}
                        onSubmit={this.bindSubmitForm}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleSubmit,
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Button variant="primary" disabled={this.state.loading} onClick={this.handleSubmit}>Save Instructors</Button>
                                </form>
                            );
                        }}
                    </Formik>

                </div>
            </div>
        );
    }
}
