import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, Modal, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      loading: false,
      password1: "",
      password2: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    switch (event.target.id) {
      case "password1":
        if (re.test(event.target.value)) {
          this.setState({ password1Err: ""  })
          this.setState({
            [event.target.id]: event.target.value
          });
        }
        else {
          this.setState({
            [event.target.id]: event.target.value
          });
          this.setState({ password1Err: "Does not meet password requirements." })
        }
        break;
      case "password2":
        if (re.test(event.target.value)) {
          this.setState({ password2Err: "" })
          this.setState({
            [event.target.id]: event.target.value
          });
        }
        else {
          this.setState({
            [event.target.id]: event.target.value
          });
          this.setState({ password2Err: "Does not meet password requirements." })
        }
        break;
      default:
        this.setState({
          [event.target.id]: event.target.value
        });
    }

  }

  handleSubmit = async event => {
    event.preventDefault();


    this.setState({ isLoading: true });

    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        console.log("user must change their password")
        this.setState({ loading: true, user: user })
      }
      else {
        this.props.userHasAuthenticated(true);
      }
      console.log(user)
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  handlePWChange = async event => {

    if (this.state.password1 === this.state.password2) {
      var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
      if (re.test(this.state.password1)) {
       let result = Auth.completeNewPassword(this.state.user, this.state.password1, [])
       console.log(result)
       this.setState({loading: false})
      }
      console.log(this.state.password1, this.state.password2)
    }
    else {
      this.setState({ password2Err: "Passwords are not equal." })

    }
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <div>Email</div>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />

          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <div>Password</div>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              autoComplete="current-password"
            />
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
          />
        </form>
        <Modal
          show={this.state.loading}
          onHide={this.handleHide}
          dialogClassName="custom-modal"
        >
          <Modal.Body>
            <br></br>
            <p className="center">You must change your password.</p>
            <p className="center">It must be a minimum of 8 characters.</p>
            <p className="center">It has to have at least one: uppercase letter, lowercase letter, number</p>
            <form onSubmit={this.handlePWChange}>
              <FormGroup controlId="password1">
                <div>New Password</div>
                <FormControl required
                  name="password1"
                  type="password"
                  value={this.state.password1}
                  placeholder="Enter text"
                  onChange={this.handleChange}
                  autoComplete="given-name"
                />
                {this.state.password1Err ?
                  <div className="error">{this.state.password1Err}</div> : null}
              </FormGroup>

              <FormGroup controlId="password2">
                <div>Confirm Password</div>
                <FormControl required
                  name="password2"
                  type="password"
                  value={this.state.password2}
                  placeholder="Enter text"
                  onChange={this.handleChange}
                  autoComplete="family-name"
                />
                <br></br>
                {this.state.password2Err ?
                  <div className="error">{this.state.password2Err}</div> : null}
              </FormGroup>
              <Button bsStyle="primary" type="submit">Submit</Button>

            </form>
          </Modal.Body>
        </Modal>
      </div >
    );
  }
}
