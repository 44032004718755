import React from 'react';
import { Formik, Field } from "formik";
import { Button, Table, Alert } from 'react-bootstrap'
import { Auth, API } from "aws-amplify";
import classnames from 'classnames';

import './SetRoadPackages.css';

// Radio input
const RadioButton = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    className,
    ...props
}) => {
    return (
        <div>
            <input
                name={name}
                id={id}
                type="radio"
                value={id} // could be something else for output?
                checked={id === value}
                onChange={onChange}
                onBlur={onBlur}
                className={classnames("radio-button")}
                {...props}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

const InputFeedback = ({ error }) =>
    error ? <div className="input-feedback">{error}</div> : null;

const RadioButtonGroup = ({
    value,
    error,
    touched,
    id,
    label,
    className,
    children
}) => {
    const classes = classnames(
        "input-field",
        {
            "is-success": value || (!error && touched), // handle prefilled or user-filled
            "is-error": !!error
        },
        className
    );
    return (
        <div className={classes}>
            <fieldset>
                <legend>{label}</legend>
                {children}
                {touched && <InputFeedback error={error} />}
            </fieldset>
        </div>
    );
};
export default class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
        this.state = {
            packages: [],
            loading: false,
            values: {},
            errors: {},
            touched: {}
        };
    }


    fetchRoadTestPackages(){
        const that = this;
        fetch('https://s3.amazonaws.com/publicdates/roadTestPackages.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                console.log(data)
                if (typeof (data) === "undefined") {
                    that.setState({ packages: [] })
                }
                else {
                    that.setState({ packages: data })
                }
            });
    }

    componentDidMount() {
        this.fetchRoadTestPackages();
    }

    onChange(e, index, field) {
        console.log(e, index, field)
        var localPackages = this.state.packages
        switch (field) {
            case "name":
                localPackages[index].package = e
                break;
            case "price":
                localPackages[index].price = e
                break;
            case "lessons":
                localPackages[index].lessons = e
                break;
            default:
                console.warn("Default value hit. Should not reach here.")
        }
        console.log(localPackages)
        this.setState({ packages: localPackages })
    }
    addNewPackage = () => {
        var localPackages = this.state.packages
        localPackages.push(
            {
                "package": "",
                "price": "",
                "editName": true,
                "lessons": 0
            }
        )
        this.setState({ packages: localPackages, refreshStatus: "", packageError: "" })
    }

    deletePackage = (index) => {
        var localPackages = this.state.packages
        localPackages.splice(index, 1)
        this.setState({ packages: localPackages, refreshStatus: "", packageError: "" })
    }

    handleErrors = (response) => {
        if (!response.ok) {
            this.setState({ loading: false, packageError: "There was an error: " + response.statusText })
        }
        return response;
    }

    handleSubmit = async event => {
        this.setState({ loading: true, refreshStatus: "", packageError: "" })
        let packages = this.state.packages.filter(x => x.package.length > 0).sort((a, b) => (a.package > b.package) ? 1 : ((b.package > a.packages) ? -1 : 0))
        const that = this;
        let session = await Auth.currentSession();
        let options = {
            body: packages,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        }
        API.post("APIGateway", "/updateroadtestpackages ", options)
            .then(function (data) {
                that.setState({ loading: false, refreshStatus: "Successfully updated!" })
                console.log("Received the following from the API")
                console.log(data)
            }).catch(function (error) {
                console.log(error)
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
    render() {
        return (
            <div className="holder">
                {this.state.packageError ? <Alert variant="warning">
                    <strong>{this.state.packageError}</strong>
                </Alert> : null}
                {this.state.refreshStatus ? <Alert variant="success">
                    <strong>{this.state.refreshStatus}</strong>
                </Alert> : null}
                <div>
                    <h3>Set Active Packages</h3>
                    <div>Be very careful with the naming of packages to ensure there are no duplicate names.</div>
                    <br></br>
                    <Button variant="success" onClick={this.addNewPackage}>Add New</Button>
                    <br></br>
                    <br></br>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Package Name</th>
                                <th>Cost ($)</th>
                                <th>Lessons</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.packages ?
                                this.state.packages.map((x, index) => {
                                    var name = x.package
                                    var price = x.price
                                    var disabled = !x.editName || this.state.loading;
                                    var lessons = x.lessons
                                    return (
                                        <tr key={index}>
                                            <td><input className="inputWidth" type='text' disabled={disabled} onChange={e => this.onChange(e.target.value, index, "name")} value={name} /></td>
                                            <td><input className="inputWidth" type='text' onChange={e => this.onChange(e.target.value, index, "price")} value={price} /></td>
                                            <td><input className="inputWidth" type='text' onChange={e => this.onChange(e.target.value, index, "lessons")} value={lessons} /></td>
                                            <td><Button variant="danger" disabled={disabled} onClick={() => this.deletePackage(index)}>Delete</Button></td>
                                        </tr>
                                    )
                                })
                                : null}
                        </tbody>
                    </Table>
                    <Formik
                        ref={this.formikRef}
                        initialValues={{
                        }}
                        onSubmit={this.bindSubmitForm}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleSubmit,
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Button variant="primary" disabled={this.state.loading} onClick={this.handleSubmit}>Save Packages</Button>
                                    <hr></hr>
                                    <h3>Form Preview</h3>
                                    <RadioButtonGroup
                                        id="roadTestPackage"
                                        label="Road Test Package"
                                        value={values.roadTestPackage}
                                        error={errors.roadTestPackage}
                                        touched={touched.roadTestPackage}
                                    >
                                        {this.state.packages.map((roadTestPackage, key) => {
                                            return (
                                                <Field
                                                    key={roadTestPackage.package + key}
                                                    component={RadioButton}
                                                    name="roadTestPackage"
                                                    id={roadTestPackage.package}
                                                    label={roadTestPackage.package + " ($" + roadTestPackage.price + ")"}
                                                />
                                            )
                                        })}
                                    </RadioButtonGroup>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        );
    }
}
