import React from 'react';
import qs from 'qs'
import moment from 'moment';

import { Alert, Image } from 'react-bootstrap'
class Confirmation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showConfirmation: false,
            loading: true,
            student: {},
            APIError: "",
            date: ""
        };
    }
    componentDidMount() {
        const values = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        this.setState({ date: moment(values.dateStart).format("MM-DD-YYYY") })
        const that = this;
        if (values.dateStart && values.token) {//both exist
            fetch('https://x4g3mwhate.execute-api.us-east-1.amazonaws.com/dev/validateemail?dateStart=' + encodeURI(values.dateStart) + '&token=' + encodeURI(values.token), {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            })
                .then(this.handleErrors)
                .then(function (response) {
                    return response.json();
                }).then(function (data) {
                    if (data.type === "success") {
                        var studentData = data.student;
                        var momentDate = moment(that.state.date);
                        var weekendArray = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]

                        if (momentDate.day() === 6) {
                            var daysToAdd = weekendArray.indexOf(studentData.weekendDate)
                            momentDate = momentDate.add(daysToAdd, "day");
                        } else {
                            switch (studentData.weekendDate) {
                                case "Tuesday":
                                    momentDate = momentDate.add(1, "day");
                                    break;
                                case "Wednesday":
                                    momentDate = momentDate.add(2, "day");
                                    break;
                                case "Thursday":
                                    momentDate = momentDate.add(3, "day");
                                    break;
                                case "Friday":
                                    momentDate = momentDate.add(4, "day");
                                    break;
                                case "Saturday":
                                    momentDate = momentDate.subtract(2, "day");
                                    break;
                                case "Sunday":
                                    momentDate = momentDate.subtract(1, "day");
                                    break;
                                default:
                                    break;
                            }
                        }

                        that.setState({ student: data.student, date: momentDate.format("MM-DD-YYYY") })
                    }
                    else {
                        that.setState({ APIError: "Unable to validate confirmation. Did you click on an old email?" })
                    }
                    that.setState({ showConfirmation: true })
                });
        }
        else {
            console.log("Missing values")
            this.setState({ showConfirmation: true, APIError: "Whoops, we couldn't find anything in our records. Please try copying and pasting the link!" })
        }
    }
    handleErrors = (response) => {
        if (!response.ok) {
            console.log(response.statusText);
        }
        return response;
    }

    render() {
        return (
            <div className="holder">
                {this.state.showConfirmation ? <div>
                    <div>
                        {this.state.APIError ?
                            <div>
                                <Alert variant="warning">
                                    <strong>{this.state.APIError}</strong>
                                </Alert>
                            </div>
                            :
                            <div>
                                <Alert variant="success">
                                    <strong>Confirmation Confirmed!</strong>
                                </Alert>
                                <p>Thanks {this.state.student.fName}, for your confirmation. We look forward to your road test on {this.state.student.weekendDate}, {this.state.date} at {this.state.student.slot}! </p>
                                <a href="/" className="btn btn-primary regButton" role="button">Return Home</a>
                            </div>
                        }

                    </div>
                    {/* These are only used so that paymentjs doesn't complain. */}
                    <input type="hidden" name="CCNumber" value=""></input>
                    <input type="hidden" name="CCExpiration" value=""></input>
                    <input type="hidden" name="CCCode" value=""></input>


                </div> : <div>
                        <Image className="centerImage" src={process.env.PUBLIC_URL + '/car-loading.gif'} responsive />
                        <br></br>
                        <p className="center">We're working on confirming your road test.</p>
                        <p className="center">Please Wait.</p>
                    </div>}


            </div>




        );
    }
}


export default Confirmation;
