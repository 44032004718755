import React from 'react';
import './Home.css';
import { Accordion, Card } from 'react-bootstrap'

export default class Registration extends React.Component {
    render() {
        return (
            <div className="holder">

                <h2>About Us</h2>
                <p>You can count on the professionals at Parkway Driving School for friendly and responsive customer service and competitive prices. We are conveniently located on the bus line and commuter rail.</p>
                <iframe title="googleMaps" width="100%" height="450" frameBorder="0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJfbJI_it_44kR0vh4lUUz6mk&key=AIzaSyAeIh33klXBfyfSnAJi-ffOSOqD1dKZM38" allowFullScreen></iframe>
                <div>
                    <p>
                        <span>Parkway Driving School offers you the full training you need to put your classroom time to the test on the road and the confidence to pass with flying colors!</span>
                    </p>
                    <p>
                        <span>If you would like additional information about our road testing process, give us a call today at </span><span><a href="tel:+16174774257" target="_self">617-477-4257</a></span><span>. You can also register by </span><span><a href="https://docs.google.com/forms/d/e/1FAIpQLScMAGY6VizTo1_W35U47jtkcdabpz7yP26Otn5DEG7ADl2jIQ/viewform?usp=pp_url" target="_blank" rel="noopener noreferrer">clicking on this link</a></span><span>.</span>
                    </p>
                    <h3>Our road testing process</h3>
                    <p>
                        <span>We submit applicants to the Registry of Motor Vehicles for approval two weeks prior to a road test. When a date and time is confirmed we will send you an email or text.  The RMV does not allow cancellations for weekend road tests.  Exact date and time will be confirmed by email or text one week before the test.</span>
                    </p>
                    <h3>Our price schedule for road testing</h3>
                    <p>See home page for pricing</p>
                    <h3>Questions and answers about road testing</h3>
                    <p>
                        <span>We submit applicants to the Registry of Motor Vehicles for approval two weeks prior to a road test. When a date and time is confirmed we will send you an email or text.  The RMV does not allow cancellations for weekend road tests.  Exact date and time will be confirmed by email or text one week before the test.</span>
                    </p>

                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                What do I need to bring with me the day of the road test?
                                </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    You will need to bring a completed application and your permit with you the day of the road test. If you are under the age of 18, your application must be signed by your parent/guardian. Use the link below to print out your application.
                                    </Card.Body>
                            </Accordion.Collapse>

                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                                Can anyone schedule a road test with Parkway Driving School?
                                                                </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    Yes. You do not need to be a Parkway Driving School student to schedule a road test with us. We do require one lesson before the road test if you are not a Parkway Driving School student.
                                    </Card.Body>
                            </Accordion.Collapse>

                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                                What is your payment and cancellation policy?
                                </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    Payment Policy: Parkway Driving School needs to be paid at least two weeks prior to the scheduled road test. Cancellation Policy: Weekend RMV road tests are a special arrangement with RMV, and Parkway Auto School is unable to offer any cancellations.
                                    </Card.Body>
                            </Accordion.Collapse>

                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                                What are the RMV fees?
                                </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    The RMV charges $50 for your driver's license and $35 for the appointment fee. You will have to pay this fee whether you go with us on the weekend or go during the week on your own. You can pay by phone by calling 857-368-8000 and follow the voice prompts to pre-pay your license fees.
                                    </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">
                                What will be required during the road test?
                                </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    <ul>
                                        <li>
                                            Use hand signals
                                        </li>
                                        <li>
                                            Start the engine
                                        </li>
                                        <li>
                                            Start and stop the vehicle
                                        </li>
                                        <li>
                                            Parallel Park
                                        </li>
                                        <li>
                                            Back the vehicle approximately 50 feet
                                        </li>
                                        <li>
                                            Make left - right turns
                                        </li>
                                        <li>
                                            Start, stop, and turn the vehcile on a hill
                                        </li>
                                        <li>
                                            Turn around between curbs (three point turn)
                                        </li>
                                        <li>
                                            Enter and leave intersections
                                        </li>
                                        <li>
                                            Recognize and obey traffic signs, lights and signals, and other rules of the road
                                        </li>
                                        <li>
                                            Use good driving sense
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <br></br>

                    <h2>Privacy Policy</h2>
                    <div>
                    Boston Road Tests LLC takes the security of your information seriously. To prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of your information, Boston Road Tests LLC has implemented numerous physical, electronic and administrative procedures to safeguard and secure the information we collect online. However, it is impossible for us to fully guarantee that these measures will prevent any or all unauthorized use of your information. We also do not share, sell or give to any 3rd party’s any personal or private information except when processing your credit card payment your credit card details are provided to our PCI Compliant Payment Processor and Gateway to process your payment only no data is saved during the payment process.</div>
                </div >
            </div >

        );
    }
}
