import React from 'react';
import { Row, Col } from 'react-bootstrap'

import './Home.css';
export default class Registration extends React.Component {

    // React components are simple functions that take in props and state, and render HTML
    render() {
        return (
            <div className="holder">
                <h2>Contact Us</h2>
                <br></br>
                <Row>
                    <Col>


                        <h3>Telephone</h3>
                        <a href="tel:617-477-4257">617-477-4257</a>

                    </Col>
                    <Col>


                        <h3>Text Line Only</h3>
                        <a href="sms:617-564-3803">617-564-3803</a>

                    </Col>
                    <Col>
                        <h3>Email</h3>
                        <a href="mailto:info@parkwaydrivingschool.com">info@parkwaydrivingschool.com</a>

                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col>

                        <h3>Office Hours</h3>
                        <p>Mon - Thu 10-4</p>

                    </Col>
                    <Col>
                        <h3>Driving Hours</h3>
                        <p>Mon - Fri 9-8</p>
                        <p>Sat - Sun 9-4</p>


                    </Col>
                </Row>




            </div >

        );
    }
}
