import { Formik, Field, FastField } from "formik";
import React from "react";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import ErrorFocus from './ErrorFocus';
import * as Yup from "yup";
import classnames from 'classnames';
import './RegistrationForm.css';
import moment from 'moment-timezone';
import { Row, Col, Modal, Image, Alert, Button } from 'react-bootstrap'
import 'react-credit-cards/es/styles-compiled.css';
import Payment from 'payment';
import './Form.css';
import Cards from 'react-credit-cards';

moment.tz.setDefault("America/New_York");

const drivingSchool = [
    { label: "I attended Parkway Driving School", value: "Parkway Driving School" },
    { label: "I attended a different driving school", value: "Other Driving School" },
    { label: "I did not attend a driving school", value: "No Driving School" }
]

const InputFeedback = ({ error }) =>
    error ? <div className="input-feedback">{error}</div> : null;

class TextInput extends React.Component {
    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.id, true);
    };

    render() {
        const classes = classnames(
            "input-field",
            {
                "is-success": this.props.value || (!this.props.error && this.props.touched), // handle prefilled or user-filled
                "is-error": !!this.props.error && this.props.touched
            },
            classnames
        );

        return (

            <div className={classes}>

                <label style={{ display: "block" }}>
                    {this.props.label}
                </label>

                <FastField name={this.props.id}
                    placeholder={this.props.placeholder}
                    type={this.props.type}

                    className={
                        this.props.error && this.props.touched ? (
                            'text-input error'
                        ) : (
                            'text-input'
                        )
                    } />
                {this.props.touched && <InputFeedback error={this.props.error} />}
            </div>
        );
    }
}

class DOBTextInput extends React.Component {
    handleBlur = () => {
        let inputDate = this.props.value
        if (moment(inputDate, "M/D/YYYY", true).isValid() || moment(inputDate, "M/D/YY", true).isValid() || moment(inputDate, "YYYY-MM-DD", true).isValid()) {
            var parsedDate = moment()
            if (moment(inputDate, "M/D/YYYY", true).isValid()) {
                parsedDate = moment(inputDate, "M/D/YYYY", true)
            }
            else if (moment(inputDate, "M/D/YY", true).isValid()) {
                parsedDate = moment(inputDate, "M/D/YY", true)
            }
            else {
                parsedDate = moment(inputDate, "YYYY-MM-DD", true)
            }
            if (parsedDate.add(18, 'years').isAfter(moment())) {
                this.props.setFieldValue("showParentConf", true)
                this.props.onBlur(this.props.id, true);
            }
        }
        else {
            this.props.onBlur(this.props.id, true);

        }
    };

    render() {
        const classes = classnames(
            "input-field",
            {
                "is-success": this.props.value || (!this.props.error && this.props.touched), // handle prefilled or user-filled
                "is-error": !!this.props.error && this.props.touched
            },
            classnames
        );
        return (

            <div className={classes}>
                <label style={{ display: "block" }}>
                    {this.props.label}
                </label>
                <input
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    type={this.props.type}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    onBlur={this.handleBlur}
                    className={
                        this.props.error && this.props.touched ? (
                            'text-input error'
                        ) : (
                            'text-input'
                        )
                    }
                />
                {this.props.touched && <InputFeedback error={this.props.error} />}
            </div>
        );
    }
}

class CCTextInput extends React.Component {
    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topics
        this.props.onBlur(this.props.id, true);
    };

    handleChange = e => {
        if (this.props.CCField === "number") {
            this.props.onChange(this.props.id, Payment.fns.formatCardNumber(e.target.value))
        }
        else if (this.props.CCField === "expiry") {
            if (e.target.value.length > 2 && e.target.value.length < 4) {
                let first = ""
                if (e.target.value.slice(2, 3) === "/") {
                    first = e.target.value.slice(0, 2) + " / "

                }
                else {
                    first = e.target.value.slice(0, 2) + " / " + e.target.value.slice(2, 3)

                }
                this.props.onChange(this.props.id, first.replace(/[^0-9\s//]/gi, ''))
            }
            else if (e.target.value.length > 4 && e.target.value.length <= 5) {
                let first = e.target.value.slice(0, 2)
                this.props.onChange(this.props.id, first.replace(/[^0-9\s//]/gi, ''))
            }
            else if (e.target.value.length > 9) {
                this.props.onChange(this.props.id, e.target.value.slice(0, 9).replace(/[^0-9\s//]/gi, ''))
            }
            else {
                this.props.onChange(this.props.id, e.target.value.replace(/[^0-9\s//]/gi, ''))

            }
        }
        else if (this.props.CCField === "cvc") {
            if (e.target.value.length > 4) {
                this.props.onChange(this.props.id, e.target.value.slice(0, 4))
            }
            else {
                this.props.onChange(this.props.id, e.target.value);
            }
        }
        else {
            // this is going to call setFieldValue and manually update values.
            this.props.onChange(this.props.id, e.target.value);
        }
        this.props.onChange("focused", this.props.CCField);

    };
    render() {
        const classes = classnames(
            "input-field",
            {
                "is-success": this.props.value || (!this.props.error && this.props.touched), // handle prefilled or user-filled
                "is-error": !!this.props.error && this.props.touched
            },
            classnames
        );

        return (

            <div className={classes}>

                <label style={{ display: "block" }}>
                    {this.props.label}
                </label>
                <input
                    name={this.props.id}
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    className={
                        this.props.error && this.props.touched ? (
                            'text-input error'
                        ) : (
                            'text-input'
                        )
                    }
                />
                {this.props.touched && <InputFeedback error={this.props.error} />}
            </div>
        );
    }
}
const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    className,
    ...props
}) => {
    return (
        <div>
            <input
                name={name}
                id={id}
                type="checkbox"
                value={props.value}
                checked={props.value}
                onChange={onChange}
                onBlur={onBlur}
                className={classnames("radio-button")}
            />
            <label htmlFor={id}>I understand that there are <strong className="error">no refunds for road tests</strong> and tests <strong className="error">  can not be rescheduled within 14 days </strong>of the date requested.</label>
            {touched[name] && <InputFeedback error={errors[name]} />}
        </div>
    );
};
const addCompletionCertificateCheckBox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    className,
    ...props
}) => {
    return (
        <div>
            <label htmlFor={id}>Pay for Driver's Education Completion Ceritification<br></br>(Only for <strong className="error">Parkway students taking teen driver ed program</strong>)</label>

            <input
                name={name}
                id={id}
                type="checkbox"
                value={props.value}
                checked={props.value}
                onChange={onChange}
                onBlur={onBlur}
                className={classnames("radio-button")}
            />
            <label htmlFor={id}>Add Teen Driver Completion Certificate Fee (+$20)</label>
            <label htmlFor={id}>If you are retaking a road test, and have already paid this before, you do not need to pay again. </label>
            {touched[name] && <InputFeedback error={errors[name]} />}
        </div>
    );
};

// Radio input
class RadioButtonDateLocation extends React.Component {
    handleChange = () => {
        this.props.form.setFieldValue("location", this.props.location, false);
        this.props.form.setFieldValue("roadTestDateOption", this.props.date, false);
        this.props.form.setFieldValue("fullRoadTestDateOption", this.props.id, false);
        this.props.form.setFieldValue("lessonDate", "", false);
        this.props.form.setFieldValue("lessonDateSlot", "", false);
        this.props.form.setFieldValue("selectedLessonChoices", [], false);

    };
    render() {
        return (
            <div>
                <input
                    name={this.props.name}
                    id={this.props.id}
                    type="radio"
                    value={this.props.id} // could be something else for output?
                    checked={this.props.id === this.props.form.values.fullRoadTestDateOption}
                    onChange={this.handleChange}
                    onBlur={this.props.onBlur}
                    className={classnames("radio-button")}
                />
                <label htmlFor={this.props.id}>{this.props.label}</label>
            </div>
        );
    }
};

// Radio input
class DrivingSchoolRadioButtonChoice extends React.Component {
    handleChange = value => {
        //console.log(value)
        if (value !== "Other Driving School") {
            this.props.onChange("drivingSchool", value);
        }
        else {
            this.props.onChange("drivingSchool", "");
        }
        this.props.onChange("drivingSchoolChoice", value);
    };
    handleBlur = () => {
        this.props.onBlur(this.props.id, true);
    };

    render() {
        return (
            <div>
                <input
                    name={this.props.name}
                    id={this.props.id}
                    type="radio"
                    value={this.props.id} // could be something else for output?
                    checked={this.props.id === this.props.value}
                    onChange={() => this.handleChange(this.props.id)}
                    onBlur={this.handleBlur}
                    className={classnames("radio-button")}
                />
                <label htmlFor={this.props.id}>{this.props.label}</label>
            </div>
        );
    }
}

// Lesson Display and Removal
class RoadTestLessonDisplayButton extends React.Component {
    clearLesson = (date, slot) => {
        //console.log(date, slot)
        let selectedLessonChoices = this.props.values
        selectedLessonChoices = selectedLessonChoices.filter(x => !(x.date === date && x.slot === slot))
        this.props.onChange("selectedLessonChoices", selectedLessonChoices);
    };
    handleBlur = () => {
        this.props.onBlur(this.props.id, true);
    };
    render() {
        return (
            <div className={'lessonTimes'}>
                {this.props.label}
                <Button variant="danger" size="sm" onClick={() => this.clearLesson(this.props.selectedLesson.date, this.props.selectedLesson.slot)}>X</Button>
            </div>
        );
    }
}

// Radio input for Road Lessons
class RoadTestRadioButtonChoice extends React.Component {
    handleLessonChange = value => {
        this.props.onChange("roadTestPackage", value);
        this.props.onChange("lessonsNeeded", this.props.package.lessons);
    };
    handleBlur = () => {
        this.props.onBlur(this.props.id, true);
    };
    render() {
        return (
            <div>
                <input
                    name={this.props.name}
                    id={this.props.id}
                    type="radio"
                    value={this.props.id}
                    checked={this.props.id === this.props.value}
                    onChange={() => this.handleLessonChange(this.props.id)}
                    className={classnames("radio-button")}
                />
                <label htmlFor={this.props.id}>{this.props.label}</label>
            </div>
        );
    }
}

// Radio group
const RadioButtonGroup = ({
    value,
    error,
    touched,
    label,
    className,
    children,
    sublabel
}) => {
    const classes = classnames(
        "input-field",
        {
            "is-success": value || (!error && touched), // handle prefilled or user-filled
            "is-error": !!error
        },
        className
    );
    return (
        <div className={classes}>
            <fieldset>
                <legend>{label}</legend>
                <label className="error">{sublabel}</label>
                {children}
                {touched && <InputFeedback error={error} />}
            </fieldset>
        </div>
    );
};

const sourceOptions = [
    { value: 'Word of Mouth', label: 'Word of Mouth' },
    { value: 'Google', label: 'Google' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Bulletin Ad', label: 'Bulletin Ad' },
    { value: 'Parkway Reminder Email', label: 'Parkway Reminder Email' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Other', label: 'Other' }
];

class FormikSelect extends React.Component {
    handleChange = value => {
        // this is going to call setFieldValue and manually update values.
        if (value.label !== "Other") {
            this.props.onChange("sourceText", value.label);
        }
        else {
            this.props.onChange("sourceText", "");
        }
        this.props.onChange(this.props.id, value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched
        this.props.onBlur(this.props.id, true);
    };

    render() {
        return (
            <div style={{ margin: "1rem 0" }}>
                <label htmlFor="color">How did you hear about us?</label>
                <Select
                    id="color"
                    options={this.props.options}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={Object.entries(this.props.value).length === 0 && this.props.value.constructor === Object ? null : this.props.value}
                />
                {this.props.touched && <InputFeedback error={this.props.error && this.props.error.value} />}
            </div>
        );
    }
}


class FormikSelectRoadLessonDate extends React.Component {

    handleChange = value => {
        this.props.onChange(this.props.id, value.value);
        let allLessonData = this.props.fetchedLessons
        this.props.onChange("selectedDayLessonOptions", allLessonData[value.value].sort((a, b) => (a.startTime > b.startTime) ? 1 : -1).map(x => ({ "value": x.timeSlot, "label": x.timeSlot })))
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched
        this.props.onBlur(this.props.id, true);
    };
    loadOptions = (inputValue, callback) => {
        let that = this;
        let roadTestDate = this.props.selectedDay.substring(0, 10)
        fetch('https://x4g3mwhate.execute-api.us-east-1.amazonaws.com/dev/getpubliclessondates', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify({ "start": roadTestDate, "location": this.props.location }
            )
        }).then(that.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                let roadTestLessonDates = Object.keys(data)
                    .filter(date => moment(date).isBefore(moment(roadTestDate)))
                    .map(x => ({ "value": x, "label": moment(x).format('MM/DD/YYYY') + " (" + (moment(x).format('dddd')) + ")" }));
                that.props.onChange("fetchedLessons", data);
                callback(roadTestLessonDates)
            });
    };

    render() {
        let roadTestDate = this.props.selectedDay.substring(0, 10)

        return (
            <div style={{ margin: "1rem 0" }}>
                <AsyncSelect
                    key={roadTestDate + this.props.location}
                    cacheOptions={false}
                    loadOptions={this.loadOptions}
                    defaultOptions
                    noOptionsMessage={() => "No lessons avaliable for " + roadTestDate + "."}
                    onChange={this.handleChange}
                />
                {this.props.touched && <InputFeedback error={this.props.error && this.props.error.value} />}
            </div>
        );
    }
}

class FormikAddLesson extends React.Component {
    handleChange = (date, slot, location) => {
        let lesson = {
            "date": date,
            "slot": slot,
            "location": location
        }

        let selectedLessonChoices = this.props.selectedLessonChoices

        let matchedLessonChoices = selectedLessonChoices.filter(x => x.date === date && x.slot === slot)

        if (matchedLessonChoices.length === 0) {
            selectedLessonChoices.push(lesson)
            this.props.onChange("selectedLessonChoices", selectedLessonChoices);
        }
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched
        this.props.onBlur(this.props.id, true);
    };
    loadOptions = (inputValue, callback) => {
        callback(this.props.selectedDayLessonOptions)
    };

    render() {
        return (
            <button type="button" onClick={() => this.handleChange(this.props.lessonDate, this.props.lessonDateSlot, this.props.lessonLocation)}>
                Add Lesson
            </button>
        );
    }
}

class FormikSelectRoadLessonDateSlot extends React.Component {
    handleChange = value => {
        this.props.onChange(this.props.id, value.value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched
        this.props.onBlur(this.props.id, true);
    };
    loadOptions = (inputValue, callback) => {
        callback(this.props.selectedDayLessonOptions)
    };

    render() {
        let roadTestDate = this.props.selectedDay.substring(0, 10)
        return (
            <div style={{ margin: "1rem 0" }}>
                <AsyncSelect
                    key={"slots" + roadTestDate}
                    cacheOptions={false}
                    loadOptions={this.loadOptions}
                    defaultOptions
                    onChange={this.handleChange}
                />
                {this.props.touched && <InputFeedback error={this.props.error && this.props.error.value} />}
            </div>
        );
    }
}
/* const DisplayFormikState = props =>
    <div style={{ margin: '1rem 0' }}>

        <pre
            style={{
                background: '#f6f8fa',
                fontSize: '.65rem',
                padding: '.5rem',
            }}
        >
            <strong>props</strong> = {JSON.stringify(props, null, 2)}
        </pre>
    </div> */

class RegistrationForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            roadTestDates: [],
            avaliableOptions: [],
            loading: false,
            showConfirmation: false,
            lessonsNeeded: 0,
            selectedLessons: [],
            apiResponseError: "",
            roadTestDatesFile: {}
        };
        this.formikRef = React.createRef();
    }
    handleErrors = (response) => {
        if (!response.ok) {
            console.log(response.statusText);
        }
        return response;
    }
    responseHandler = (response) => {
        try {
            let formikVals = this.formikRef.current.values;
            if (response.messages.resultCode === "Error") {
                var i = 0;
                while (i < response.messages.message.length) {
                    console.log(
                        response.messages.message[i].code + ": " +
                        response.messages.message[i].text
                    );
                    i = i + 1;
                }
            }
            else {
                //The Authorize NET request was successful!
                //To handle how different browsers handle date input. AKA IE
                var parsedDOBDate = moment()
                var parsedPermitDate = moment()
                if (moment(formikVals.dateOfBirth, "M/D/YYYY", true).isValid()) {
                    parsedDOBDate = moment(formikVals.dateOfBirth, "M/D/YYYY", true)
                }
                else if (moment(formikVals.dateOfBirth, "M/D/YY", true).isValid()) {
                    parsedDOBDate = moment(formikVals.dateOfBirth, "M/D/YY", true)
                }
                else {
                    parsedDOBDate = moment(formikVals.dateOfBirth, "YYYY-MM-DD", true)
                }
                if (moment(formikVals.permitIssueDate, "M/D/YYYY", true).isValid()) {
                    parsedPermitDate = moment(formikVals.permitIssueDate, "M/D/YYYY", true)
                }
                else if (moment(formikVals.permitIssueDate, "M/D/YY", true).isValid()) {
                    parsedPermitDate = moment(formikVals.permitIssueDate, "M/D/YY", true)
                }
                else {
                    parsedPermitDate = moment(formikVals.permitIssueDate, "YYYY-MM-DD", true)
                }
                const self = this;
                let nonce = response.opaqueData.dataValue;
                let body = {
                    fName: formikVals.firstName,
                    lName: formikVals.lastName,
                    dob: parsedDOBDate.format("YYYY-MM-DD"),
                    permitNo: formikVals.permitNumber,
                    permitDate: parsedPermitDate.format("YYYY-MM-DD"),
                    email: formikVals.email,
                    parentEmail: formikVals.parentEmail,
                    phone: formikVals.phoneNumber,
                    package: formikVals.roadTestPackage,
                    roadTestChoiceDate: formikVals.roadTestDateOption,
                    drivingSchool: formikVals.drivingSchool,
                    nonce: nonce,
                    source: Object.keys(formikVals.source).length > 0 ? formikVals.source.label : "None Specified",
                    CCFirstName: formikVals.CCFirstName,
                    CCLastName: formikVals.CCLastName,
                    CCAddress: formikVals.CCAddress,
                    CCCity: formikVals.CCCity,
                    CCState: formikVals.CCState,
                    CCZip: formikVals.CCZip,
                    CCCountry: formikVals.CCCountry,
                    addCompletionCertificate: formikVals.addCompletionCertificate,
                    location: formikVals.location
                }

                if (formikVals.lessonsNeeded > 0) {
                    body["selectedLessonChoices"] = formikVals.selectedLessonChoices
                }

                fetch('https://x4g3mwhate.execute-api.us-east-1.amazonaws.com/dev/submitpayment', {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    body: JSON.stringify(body)
                })
                    .then(function (response) {
                        return response.json();
                    }).then(function (data) {
                        if (data.type === 'success') {
                            //stop the modal loading.
                            //change the page to a success!
                            self.formikRef.current.resetForm();

                            self.setState({
                                showConfirmation: true,
                            })
                        }
                        else {
                            self.formikRef.current.setSubmitting(false)
                            if (["Transaction Failed with error code 2 : This transaction has been declined.", "Transaction Failed with error code 65 : This transaction has been declined."].includes(data.message)) {
                                self.formikRef.current.setErrors({
                                    apiResponseError: "There was an issue processing your card. Please double check your credit card entries."
                                })
                            }
                            else {
                                self.formikRef.current.setErrors(
                                    {
                                        apiResponseError: data.message
                                    }
                                )
                            }
                        }
                    }
                    );
            }
        } catch (error) {
            console.log(error);
        }
    }

    bindSubmitForm = (values, { setErrors, setSubmitting }) => {
        var errors = {}
        //Now we need additional proccessing to make sure the form is valid.
        var validNumber = Payment.fns.validateCardNumber(values.CCNumber)
        var validExpiration = Payment.fns.validateCardExpiry(values.CCExpiration)
        var validCVC = Payment.fns.validateCardCVC(values.CCCode);
        if (!validNumber) {

            errors.CCNumber = "This number is not a valid credit card number."
        }
        else {
            var cardType = Payment.fns.cardType(values.CCNumber)
            if (cardType === "visa" || cardType === "mastercard" || cardType === "discover") {
            }
            else {
                errors.CCNumber = "Sorry, we only accept Visa, Mastercard, and Discover."
            }
        }
        if (!validExpiration) {
            errors.CCExpiration = "Invalid Expiration Date"
        }
        if (!validCVC) {
            errors.CCCode = "Invalid CVC Code"
        }

        if (moment(values.dateOfBirth, "M/D/YYYY", true).isValid() || moment(values.dateOfBirth, "M/D/YY", true).isValid() || moment(values.dateOfBirth, "YYYY-MM-DD", true).isValid()) {
            var parsedDate = moment()
            if (moment(values.dateOfBirth, "M/D/YYYY", true).isValid()) {
                parsedDate = moment(values.dateOfBirth, "M/D/YYYY", true)
            }
            else if (moment(values.dateOfBirth, "M/D/YY", true).isValid()) {
                parsedDate = moment(values.dateOfBirth, "M/D/YY", true)
            }
            else {
                parsedDate = moment(values.dateOfBirth, "YYYY-MM-DD", true)
            }

            if (parsedDate.isAfter(moment()) || parsedDate.add(16, 'years').isAfter(moment())) {
                errors.dateOfBirth = "You're a bit too young to try to book a road test. Come back when you're old enough."

            }
            else if (parsedDate.isBefore(moment().subtract(150, 'years'))) {
                errors.dateOfBirth = "Wow, you're over 150 years old. Please contact us directly to book your road test."
            }
        }
        else {
            errors.dateOfBirth = "Invalid Date of Birth Format. Please enter it in MM/DD/YYYY form."
        }

        if (moment(values.permitIssueDate, "M/D/YYYY", true).isValid() || moment(values.permitIssueDate, "M/D/YY", true).isValid() || moment(values.permitIssueDate, "YYYY-MM-DD", true).isValid()) {
            var parsedPermitDate = moment()
            if (moment(values.permitIssueDate, "M/D/YYYY", true).isValid()) {
                parsedPermitDate = moment(values.permitIssueDate, "M/D/YYYY", true)
            }
            else if (moment(values.permitIssueDate, "M/D/YY", true).isValid()) {
                parsedPermitDate = moment(values.permitIssueDate, "M/D/YY", true)
            }
            else {
                parsedPermitDate = moment(values.permitIssueDate, "YYYY-MM-DD", true)
            }

            if (parsedPermitDate.isBefore(moment().subtract(2, "years"))) {
                errors.permitIssueDate = "It looks like your permit has expired."
            }
            else if (parsedPermitDate.isAfter(moment())) {
                errors.permitIssueDate = "You can't have a permit issued from the FUTURE."
            }
            if (values.roadTestDateOption) {
                if (parsedPermitDate.isAfter(moment(values.roadTestDateOption).subtract(6, "months"))) {
                    if (moment(values.dateOfBirth, "M/D/YYYY", true).isValid() || moment(values.dateOfBirth, "M/D/YY", true).isValid() || moment(values.dateOfBirth, "YYYY-MM-DD", true).isValid()) { //Check if date is valid
                        var birthDate = moment()
                        if (moment(values.dateOfBirth, "M/D/YYYY", true).isValid()) {
                            birthDate = moment(values.dateOfBirth, "M/D/YYYY", true)
                        }
                        else if (moment(values.dateOfBirth, "M/D/YY", true).isValid()) {
                            birthDate = moment(values.dateOfBirth, "M/D/YY", true)
                        }
                        else {
                            birthDate = moment(values.dateOfBirth, "YYYY-MM-DD", true)
                        }
                        if (!birthDate.isBefore(moment().subtract(18, "years"))) { //6 month restriction only applies to 16 and a half to 18
                            errors.permitIssueDate = "As a junior operator, you need to have your permit for a minimum of 6 months prior to a road test."
                        }
                    }
                    else { //Date's not valid
                        errors.dateOfBirth = "Invalid Date of Birth Format. Please enter it in MM/DD/YYYY form."
                    }
                }
                console.log(moment(values.roadTestDateOption).subtract(2, "years"))
                console.log(moment(values.roadTestDateOption).subtract(2, "years").isAfter(parsedPermitDate))
                if (moment(values.roadTestDateOption).subtract(2, "years").isAfter(parsedPermitDate)) {
                    errors.permitIssueDate = "Your permit is two years older than the road test requested, and wouldn't be able to be used for that road test."
                }
            }
        }
        else {
            errors.permitIssueDate = "Invalid Permit Issue Date Format. Please enter it in MM/DD/YYYY form."
        }
        if (moment(values.roadTestDateOption).isBefore(moment())) {
            errors.roadTestDateOption = "You can not book a road test in the past! Please choose another date."
        }

        if (values.lessonsNeeded > values.selectedLessonChoices.length) {
            errors.lessonDate = "You haven't selected enough lessons!"
        }
        if (values.selectedLessonChoices.length > values.lessonsNeeded) {
            errors.lessonDate = "You have too many selected lessons. Please remove one!"
        }


        if (values.email !== values.confemail) {
            errors.email = "Both your email address must match."
            errors.confemail = "Both your email address must match."
        }
        if (!values.email.includes("@")) {
            errors.email = "Please enter a valid email address."
        }
        if (!values.confemail.includes("@")) {
            errors.confemail = "Please enter a valid confirmation email address."
        }

        if (values.showParentConf) { //Check if date is valid
            if (values.parentEmail !== values.confParentEmail) {
                errors.parentEmail = "Both your parent's email address must match."
                errors.confParentEmail = "Both your parent's email address must match."
            }
            if (!values.parentEmail.includes("@")) {
                errors.parentEmail = "Please enter a valid parent email address."
            }
            if (!values.confParentEmail.includes("@")) {
                errors.confParentEmail = "Please enter a valid parent confirmation email address."
            }
        }

        if (values.phoneNumber.length !== 10) {
            errors.phoneNumber = "Invalid phone number."
        }
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            setSubmitting(false)
            return
        }
        //SANDBOX
        //var authData = {
        //    clientKey: "5KP3WdKyu8vrxHcpBMD96TsDknJZG36MyazkM9Z9sUa6ja7Mq7e5L2uVN8675ZnE",
        //    apiLoginID: "7j3Mq5W6"
        //};


        //PROD
        var authData = {
            clientKey: "84HRp6YNCFC835EQpKeRttfgn7qdY95qqEJpxH6syy6vATpsE9m5R7s39WyLmy8m",
            apiLoginID: "994EhDR8c8k"
        };
        var cardData = {
            cardNumber: values.CCNumber.replace(/\s/g, ''),
            month: values.CCExpiration.substring(0, 2),
            year: values.CCExpiration.substring(values.CCExpiration.length - 2, values.CCExpiration.length),
            cardCode: values.CCCode,
            zip: values.CCZip,
            fullName: values.CCFirstName + " " + values.CCLastName
        };
        var secureData = {
            authData: authData,
            cardData: cardData
        }
        try {
            window.Accept.dispatchData(secureData, this.responseHandler)
        } catch (error) {
            console.log(error)
        }
    }

    componentDidMount() {
        const script = document.createElement("script");
        //SANDBOX
        //script.src = "https://jstest.authorize.net/v1/Accept.js";

        //PROD
        script.src = "https://js.authorize.net/v1/Accept.js";
        script.async = true;


        document.body.appendChild(script);
        const that = this;
        //We need to grab stuff
        fetch('https://s3.amazonaws.com/publicdates/roadTestDates.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                data = JSON.parse(data)
                //console.log(data)
                var roadTestDates = Object.keys(data).map(x => moment(x)).sort(function (a, b) { return a - b })
                that.setState({ roadTestDates, roadTestDatesFile: data })

            });

        fetch('https://s3.amazonaws.com/publicdates/roadTestPackages.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                //console.log(data)
                that.setState({ avaliableOptions: data })
            });

        var option = this.props.match.params.option;
        if (option === "RTO") {
            this.setState({ roadTestPackageOption: "Road Test Only ($120)" })
            window.scrollTo(0, 0)
        }
        else if (option === "RTOL") {
            this.setState({ roadTestPackageOption: "Road Test w/ One Lesson ($170)", lessonsNeeded: 1 })
            window.scrollTo(0, 0)
        }
    }
    render() {
        const RegForm = () => (
            <div className="app">
                <Formik
                    initialValues={{
                        roadTestPackage: this.state.roadTestPackageOption ? this.state.roadTestPackageOption : "", lessonsNeeded: this.state.lessonsNeeded, drivingSchool: "", roadTestDateOption: "", drivingSchoolChoice: "",
                        dateOfBirth: '', firstName: '', lastName: '', confemail: '', email: '', parentEmail: '', phoneNumber: "", permitNumber: "", permitIssueDate: "",
                        source: {}, fullRoadTestDateOption: "",
                        CCNumber: '', CCFirstName: '', CCLastName: '', CCExpiration: '', CCCode: '', focused: '',
                        CCAddress: '', CCCity: '', CCState: '', CCZip: '', CCCountry: 'USA',
                        confirmCheck: false, addCompletionCertificate: false, fetchedLessons: {}, selectedDayLessonOptions: [], selectedLessonChoices: [], showParentConf: false
                    }}
                    innerRef={this.formikRef}
                    onSubmit={this.bindSubmitForm}
                    validateOnChange={true}
                    validationSchema={
                        Yup.object().shape({
                            fullRoadTestDateOption: Yup.string()
                                .required('Please indicate which road test date you would like'),
                            roadTestPackage: Yup.string()
                                .min(2, 'Too Short!')
                                .required('Please indicate which driving package you would like'),
                            source: Yup.object().shape({
                                value: Yup.string()
                                    .required("Won't you tell us how you heard about us?")
                            }),
                            sourceText: Yup.string()
                                .min(2, 'Too Short!')
                                .required('Please specify where you heard about us'),
                            drivingSchoolChoice: Yup.string()
                                .min(2, 'Too Short!')
                                .required('Please specify your driving school'),
                            drivingSchool: Yup.string()
                                .min(2, 'Too Short!')
                                .required('Please specify your driving school'),
                            firstName: Yup.string()
                                .min(2, 'Too Short!')
                                .required('A first name is required'),
                            lastName: Yup.string()
                                .min(2, 'Too Short!')
                                .required('A last name is required'),
                            dateOfBirth: Yup.date().typeError("Invalid date")
                                .required('A date of birth is required'),
                            permitNumber: Yup.string()
                                .min(2, 'Too Short!')
                                .required('A permit number is required'),
                            permitIssueDate: Yup.date().typeError("Invalid date")
                                .required('A permit issue date is required'),
                            email: Yup.string()
                                .min(2, 'Too Short!')
                                .required('An email is required'),
                            confemail: Yup.string()
                                .min(2, 'Too Short!')
                                .required('Please retype in your email'),
                            phoneNumber: Yup.number()
                                .min(10, 'Too Short!')
                                .required('A phone number is required'),
                            CCNumber: Yup.number().typeError("Invalid number")
                                .min(10, 'Too Short!')
                                .required('A credit card number is required'),
                            CCFirstName: Yup.string()
                                .min(2, 'Too Short!')
                                .required('First name is required'),
                            CCLastName: Yup.string()
                                .min(2, 'Too Short!')
                                .required('Last name is required'),
                            CCCode: Yup.number().typeError("Invalid number")
                                .required('The CVC code is required'),
                            CCAddress: Yup.string()
                                .min(2, 'Too Short!')
                                .required('The billing address is required'),
                            CCExpiration: Yup.string().min(7, 'Too Short!')
                                .required('The expiration of the card is required'),
                            CCCity: Yup.string()
                                .min(2, 'Too Short!')
                                .required('The billing city is required'),
                            CCState: Yup.string()
                                .min(2, 'Too Short!')
                                .required('The billing state is required'),
                            CCZip: Yup.number()
                                .min(5, 'Too Short!')
                                .required('The billing zipcode is required'),
                            CCCountry: Yup.string()
                                .min(3, 'Too Short!')
                                .required('The billing country is required'),
                            confirmCheck: Yup.bool().oneOf([true], "You have to agree!")
                        })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched,
                            isSubmitting,
                            submitCount
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <ErrorFocus />
                                <RadioButtonGroup
                                    id="fullRoadTestDateOption"
                                    label="Preferred Date (Subject to RMV availability)"
                                    sublabel="Although we usually get the date we request, all dates are subject to RMV availability."
                                    value={values.fullRoadTestDateOption}
                                    error={errors.fullRoadTestDateOption}
                                    touched={touched.fullRoadTestDateOption}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                >

                                    {/* {this.state.roadTestDates.filter(x => moment(x).add(16, 'hours').subtract(12, 'days').isAfter(moment())) */}
                                    {this.state.roadTestDates.filter(x => moment(x).isAfter(moment()))
                                        .sort(function (a, b) { return a - b })
                                        .map((roadTestDate, index) => {
                                            var parsedDate = roadTestDate.toISOString()
                                            var locations = [];
                                            if (this.state.roadTestDatesFile[parsedDate] && this.state.roadTestDatesFile[parsedDate].locations) {
                                                locations = this.state.roadTestDatesFile[parsedDate].locations
                                            }
                                            if (roadTestDate.day() === 6) {
                                                return (
                                                    locations.map((location, key) => {
                                                        return (
                                                            <Field
                                                                key={roadTestDate + location + key}
                                                                component={RadioButtonDateLocation}
                                                                date={roadTestDate.format("YYYY-MM-DD")}
                                                                location={location}
                                                                name="fullRoadTestDateOption"
                                                                id={roadTestDate.format("YYYY-MM-DD") + "|" + location}
                                                                label={" Weekend of " + moment(roadTestDate).format('M/D') + " (Sat-Sun) at " + location}
                                                            />
                                                        )
                                                    })
                                                )
                                            }
                                            else {
                                                return (
                                                    locations.map((location, key) => {
                                                        return (
                                                            <Field
                                                                key={roadTestDate + location + key}
                                                                component={RadioButtonDateLocation}
                                                                date={roadTestDate.format("YYYY-MM-DD")}
                                                                location={location}
                                                                name="fullRoadTestDateOption"
                                                                id={roadTestDate.format("YYYY-MM-DD") + "|" + location}
                                                                label={" Weekday " + moment(roadTestDate).format('M/D') + " (" + moment(roadTestDate).format('ddd') + ") at " + location}
                                                            />
                                                        )
                                                    })
                                                )
                                            }
                                        })
                                    }

                                </RadioButtonGroup>
                                <RadioButtonGroup
                                    id="roadTestPackage"
                                    label="Road Test Package"
                                    value={values.roadTestPackage}
                                    error={errors.roadTestPackage}
                                    touched={touched.roadTestPackage}
                                >
                                    {this.state.avaliableOptions.map((roadTestPackage, key) => {
                                        return (
                                            <RoadTestRadioButtonChoice
                                                key={roadTestPackage.package + key}
                                                name="roadTestPackage"
                                                id={roadTestPackage.package + " ($" + roadTestPackage.price + ")"}
                                                label={roadTestPackage.package + " ($" + roadTestPackage.price + ")"}
                                                value={values.roadTestPackage}
                                                package={roadTestPackage}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                            />
                                        )
                                    })}
                                </RadioButtonGroup>
                                {values.lessonsNeeded > 0 ?
                                    <div style={{ margin: "1rem 0" }}>
                                        <label htmlFor="color">Requested Lesson Times</label>

                                        {values.roadTestDateOption ?
                                            <div>
                                                <div>Please select your {values.lessonsNeeded} lesson{values.lessonsNeeded === 1 ? "" : "s"}</div>
                                                {values.selectedLessonChoices.length > 0 ?

                                                    <div>

                                                        {values.selectedLessonChoices.map((selectedLesson, key) => {
                                                            let dow = moment(selectedLesson.date).format('dddd')

                                                            return (
                                                                <RoadTestLessonDisplayButton
                                                                    key={selectedLesson.date + selectedLesson.slot}
                                                                    name="roadTestPackage"
                                                                    id={selectedLesson.date + selectedLesson.slot}
                                                                    label={dow + " " + moment(selectedLesson.date).format('MM/DD/YYYY') + " at " + selectedLesson.slot + " at " + selectedLesson.location + "  "}
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    selectedLesson={selectedLesson}
                                                                    values={values.selectedLessonChoices}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                    :

                                                    <div>Hmm... You haven't selected any times yet.</div>

                                                }
                                                {errors.lessonDate ? <InputFeedback error={errors.lessonDate} /> : null}
                                                {values.lessonsNeeded > values.selectedLessonChoices.length ?
                                                    <div>
                                                        <label htmlFor="color">Date of Lesson</label>

                                                        <FormikSelectRoadLessonDate
                                                            id="lessonDate"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            selectedDay={values.roadTestDateOption}
                                                            location={values.location}
                                                            fetchedLessons={values.fetchedLessons}
                                                        />
                                                        {values.lessonDate ?
                                                            <div>
                                                                <label htmlFor="color">Time Slot of Lesson</label>
                                                                <FormikSelectRoadLessonDateSlot
                                                                    id="lessonDateSlot"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    selectedDay={values.lessonDate}
                                                                    selectedDayLessonOptions={values.selectedDayLessonOptions}
                                                                />
                                                            </div> : null
                                                        }

                                                        {values.lessonDateSlot ?
                                                            <FormikAddLesson
                                                                id="addLessonButton"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                lessonDate={values.lessonDate}
                                                                lessonDateSlot={values.lessonDateSlot}
                                                                lessonLocation={values.location}
                                                                selectedLessonChoices={values.selectedLessonChoices}
                                                            />

                                                            : null}
                                                        <div>Note: Lessons are not allowed on road test days.</div>
                                                    </div>
                                                    : null}
                                            </div> : <div>Please select a preferred road test date to view available lesson dates.</div>
                                        }

                                    </div> : null
                                }

                                <RadioButtonGroup
                                    id="drivingSchoolChoice"
                                    label="Driving School Attended"
                                    value={values.drivingSchoolChoice}
                                    error={errors.drivingSchoolChoice}
                                    touched={touched.drivingSchoolChoice}
                                >
                                    {drivingSchool.map((drivingSchoolRadio, key) => {
                                        return (
                                            <DrivingSchoolRadioButtonChoice
                                                key={drivingSchoolRadio.value + key}
                                                name="drivingSchool"
                                                id={drivingSchoolRadio.value}
                                                label={drivingSchoolRadio.label}
                                                value={values.drivingSchoolChoice}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                            />
                                        )
                                    })}
                                </RadioButtonGroup>

                                {values.drivingSchoolChoice === "Parkway Driving School" ?
                                    <Field
                                        component={addCompletionCertificateCheckBox}
                                        name="addCompletionCertificate"
                                        id="addCompletionCertificate"
                                        value={values.addCompletionCertificate}
                                    /> : null}



                                {values.drivingSchoolChoice === "Other Driving School" ?
                                    <TextInput
                                        id="drivingSchool"
                                        type="text"
                                        label="Other Driving School Name"
                                        placeholder="Enter other driving school name"
                                        error={errors.drivingSchool}
                                        value={values.drivingSchool}
                                        onChange={handleChange}
                                        onBlur={setFieldTouched}
                                        touched={touched.drivingSchool}
                                    /> : null}
                                <FormikSelect
                                    id="source"
                                    options={sourceOptions}
                                    value={values.source}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.source}
                                    touched={touched.source}
                                />
                                {values.source && values.source.value === "Other" ?
                                    <TextInput
                                        id="sourceText"
                                        type="text"
                                        label="Other Source"
                                        placeholder="Enter other source"
                                        error={errors.sourceText}
                                        value={values.sourceText}
                                        onChange={handleChange}
                                        onBlur={setFieldTouched}
                                        touched={touched.sourceText}
                                    /> : null}

                                <Row>
                                    <Col>
                                        <TextInput
                                            id="firstName"
                                            type="text"
                                            label="Full Legal First Name (of the individual taking the exam)"
                                            placeholder="Enter text"
                                            error={errors.firstName}
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={setFieldTouched}
                                            touched={touched.firstName}
                                        />
                                    </Col>
                                    <Col>
                                        <TextInput
                                            id="lastName"
                                            type="text"
                                            label="Full Legal Last Name (of the individual taking the exam)"
                                            placeholder="Enter text"
                                            error={errors.lastName}
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={setFieldTouched}
                                            touched={touched.lastName}
                                        />
                                    </Col>
                                </Row>
                                <DOBTextInput
                                    id="dateOfBirth"
                                    type="date"
                                    label="Date of Birth (of the individual taking the exam)"
                                    placeholder="MM/DD/YYYY"
                                    setFieldValue={setFieldValue}
                                    error={errors.dateOfBirth}
                                    value={values.dateOfBirth}
                                    onChange={handleChange}
                                    onBlur={setFieldTouched}
                                    touched={touched.dateOfBirth}

                                />
                                <div>{"Example: 01/01/2000"}</div>
                                <Row>
                                    <Col>
                                        <TextInput
                                            id="permitNumber"
                                            type="text"
                                            label="Permit Number"
                                            placeholder="Enter text"
                                            error={errors.permitNumber}
                                            value={values.permitNumber}
                                            onChange={handleChange}
                                            onBlur={setFieldTouched}
                                            touched={touched.permitNumber}

                                        />
                                    </Col>
                                    <Col>
                                        <TextInput
                                            id="permitIssueDate"
                                            type="date"
                                            label="Permit Issue Date"
                                            placeholder="MM/DD/YYYY"
                                            error={errors.permitIssueDate}
                                            value={values.permitIssueDate}
                                            onChange={handleChange}
                                            onBlur={setFieldTouched}
                                            touched={touched.permitIssueDate}

                                        /> <div>{"Example: 01/01/2000"}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextInput
                                            id="email"
                                            type="text"
                                            label="Email"
                                            placeholder="Enter Text"
                                            error={touched.email && errors.email}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={setFieldTouched}
                                            touched={touched.email}
                                        />
                                    </Col>
                                    <Col>
                                        <TextInput
                                            id="confemail"
                                            type="text"
                                            label="Confirm Email"
                                            placeholder="Enter Text"
                                            error={errors.confemail}
                                            value={values.confemail}
                                            onChange={handleChange}
                                            onBlur={setFieldTouched}
                                            touched={touched.confemail}
                                        />
                                    </Col>
                                </Row>
                                {values.showParentConf ?
                                    <Row>
                                        <Col>
                                            <TextInput
                                                id="parentEmail"
                                                type="text"
                                                label="Parent Email"
                                                placeholder="Enter Text"
                                                error={touched.parentEmail && errors.parentEmail}
                                                value={values.parentEmail}
                                                onChange={handleChange}
                                                onBlur={setFieldTouched}
                                                touched={touched.parentEmail}
                                            />
                                        </Col>
                                        <Col>
                                            <TextInput
                                                id="confParentEmail"
                                                type="text"
                                                label="Confirm Parent Email"
                                                placeholder="Enter Text"
                                                error={errors.confParentEmail}
                                                value={values.confParentEmail}
                                                onChange={handleChange}
                                                onBlur={setFieldTouched}
                                                touched={touched.confParentEmail}
                                            />
                                        </Col>
                                    </Row>
                                    :
                                    null}
                                <TextInput
                                    id="phoneNumber"
                                    type="tel"
                                    label="Phone Number"
                                    placeholder="Enter Text"
                                    error={errors.phoneNumber}
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={setFieldTouched}
                                    touched={touched.phoneNumber}
                                />
                                <br></br>
                                <h4>Payment</h4>
                                <div className="card card-body bg-light">
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <CCTextInput
                                                        id="CCNumber"
                                                        type="number"
                                                        placeholder="Card Number"
                                                        error={errors.CCNumber}
                                                        value={values.CCNumber}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCNumber}
                                                        CCField="number"

                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <CCTextInput
                                                        id="CCFirstName"
                                                        type="text"
                                                        placeholder="First Name"
                                                        error={errors.CCFirstName}
                                                        value={values.CCFirstName}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCFirstName}
                                                        CCField="name"
                                                    />
                                                </Col>
                                                <Col>
                                                    <CCTextInput
                                                        id="CCLastName"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        error={errors.CCLastName}
                                                        value={values.CCLastName}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCLastName}
                                                        CCField="name"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <CCTextInput
                                                        id="CCExpiration"
                                                        type="text"
                                                        placeholder="MM/YY"
                                                        error={errors.CCExpiration}
                                                        value={values.CCExpiration}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCExpiration}
                                                        CCField="expiry"
                                                    />
                                                </Col>
                                                <Col>
                                                    <CCTextInput
                                                        id="CCCode"
                                                        type="text"
                                                        placeholder="CVC"
                                                        error={errors.CCCode}
                                                        value={values.CCCode}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCCode}
                                                        CCField="cvc"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <TextInput
                                                        id="CCAddress"
                                                        type="text"
                                                        placeholder="Billing Address"
                                                        error={errors.CCAddress}
                                                        value={values.CCAddress}
                                                        onChange={handleChange}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCAddress}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <TextInput
                                                        id="CCCity"
                                                        type="text"
                                                        placeholder="City"
                                                        error={errors.CCCity}
                                                        value={values.CCCity}
                                                        onChange={handleChange}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCCity}
                                                    />
                                                </Col>
                                                <Col>
                                                    <TextInput
                                                        id="CCState"
                                                        type="text"
                                                        placeholder="State"
                                                        error={errors.CCState}
                                                        value={values.CCState}
                                                        onChange={handleChange}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCState}
                                                    />
                                                </Col>
                                            </Row> <Row>
                                                <Col>
                                                    <TextInput
                                                        id="CCZip"
                                                        type="text"
                                                        placeholder="Zipcode"
                                                        error={errors.CCZip}
                                                        value={values.CCZip}
                                                        onChange={handleChange}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCZip}
                                                    />
                                                </Col>
                                                <Col>
                                                    <TextInput
                                                        id="CCCountry"
                                                        type="text"
                                                        placeholder="Country"
                                                        error={errors.CCCountry}
                                                        value={values.CCCountry}
                                                        onChange={handleChange}
                                                        onBlur={setFieldTouched}
                                                        touched={touched.CCCountry}
                                                    />
                                                </Col>
                                            </Row>
                                            <br></br>
                                        </Col>
                                        <Col >
                                            <br></br>
                                            <Cards
                                                number={values.CCNumber}
                                                name={values.CCFirstName + " " + values.CCLastName}
                                                expiry={values.CCExpiration.replace(/ /g, '')}
                                                cvc={values.CCCode}
                                                focused={values.focused}
                                                acceptedCards={['visa', 'mastercard', "discover"]}
                                            />
                                            <br />
                                            <Col>
                                                <Row className="d-flex justify-content-center">
                                                    <img
                                                        className="d-flex justify-content-center"
                                                        src={process.env.PUBLIC_URL + '/visa.jpg'}
                                                    />     <img
                                                        className="d-flex justify-content-center"
                                                        src={process.env.PUBLIC_URL + '/mastercard.gif'}
                                                    />     <img
                                                        className="d-flex justify-content-center"
                                                        src={process.env.PUBLIC_URL + '/discover.gif'}
                                                    />
                                                </Row>
                                            </Col>


                                        </Col>
                                    </Row>
                                </div>

                                <Field
                                    component={Checkbox}
                                    name="confirmCheck"
                                    id="confirmCheck"
                                    value={values.confirmCheck}
                                />

                                {submitCount == 0 ? null : <div>
                                    {
                                        Object.keys(errors).length > 0 ?
                                            <div>
                                                Please correct the following errors and resubmit:
                                                <br></br>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        Object.keys(errors).map(error => {
                                            if (errors[error] && errors[error].value) {
                                                return (
                                                    errors[error].value)
                                            }
                                            else {
                                                return (
                                                    errors[error]
                                                )
                                            }
                                        }).map((error, index) => {
                                            return <div key={index} className="input-feedback">{JSON.stringify(error)}</div>

                                        })
                                    }

                                </div>}

                                <br></br>
                                <button type="submit" disabled={isSubmitting}>
                                    Submit
                                </button>
                            </form>
                        );
                    }}
                </Formik>
            </div >
        );
        return (<div className="holder" >
            {this.state.showConfirmation ?
                <div>
                    <Alert variant="success">
                        <strong>Payment successfully processed!</strong>
                    </Alert>
                    <p>Thanks for signing up. Once we get confirmation from the RMV, we will email you to confirm your Road Test date. </p>
                    <a href="/" className="btn btn-primary regButton" role="button">Return Home</a>
                </div> :
                <div className="holder">
                    <h2>Parkway Driving School Road Test Sign Up</h2>
                    <div>
                        <h4>Under 18 Students</h4>
                        <ul>
                            <li>Anyone under 18 must have their permit for 6 months before taking a road test.</li>
                            <li>Parkway Students can sign up for a road test when they have scheduled their last lesson.  Please choose a date two weeks after the last lesson.</li>
                            <li>If you are not a Parkway student, driver education must be complete and a completion certificate must be on file with the RMV <strong className="error">21 days</strong> before a road test appointment.</li>
                        </ul>
                        <h4>Road Test Dates</h4>
                        <ul>
                            <li>Dates are assigned by the RMV when Examiners are available.</li>
                            <li>You must be <strong className="error"> available both weekend days.</strong></li>
                            <li>You will be sent your date and time one week before the test.</li>
                        </ul>
                        <h4>General Information</h4>
                        <ul>
                            <li><strong className="refundwarn" >Due to the limited test slots, there are NO Refunds or cancellations for a road test for any reason including illness, emergency or rejection by the RMV examiner.</strong></li>
                            <li>You will recieve a confirmation email immediately after payment.</li>
                            <li>If you arrive for your road test without your permit, glasses or proper footware, you will not be allowed to test.</li>
                        </ul>
                    </div>
                    <RegForm></RegForm>
                </div>}
            <Modal
                show={this.state.loading}
                onHide={this.handleHide}
                dialogClassName="custom-modal"
            >
                <Modal.Body>
                    <Image src={process.env.PUBLIC_URL + '/car-loading.gif'} className="img-fluid d-block mx-auto" />
                    <br></br>
                    <p className="center">We're working on processing your order.</p>
                    <p className="center">Please Wait.</p>
                </Modal.Body>
            </Modal>
        </div>
        )
    }
}

export default RegistrationForm