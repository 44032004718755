import moment from 'moment';
import React from 'react';
import { Alert, Carousel, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './Home.css';


export default class Registration extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            startDate: moment(),
            packages: [],
            avaliableDates: [],
            westonDates: [],
            westRoxburyDates: [],
            locationDates: []
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    componentDidMount() {
        const that = this;
        fetch('https://s3.amazonaws.com/publicdates/roadTestPackages.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                console.log(data)
                that.setState({ packages: data })

            });

        fetch('https://s3.amazonaws.com/publicdates/roadTestDates.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                data = JSON.parse(data)
                console.log(data)
                if (data !== null) {
                    var avaliableDates = Object.keys(data).filter(x => moment(x).isAfter(moment()))
                        .map(x => moment(x))
                        .sort(function (a, b) { return a - b })
                    console.log(data, avaliableDates)

                    var avaliableDatesKeys = avaliableDates.map(x => x.toISOString())

                    var locationDates = [];

                    avaliableDatesKeys.forEach(function (date) {
                        console.log(date)
                        var locations = data[date].locations;
                        var dateText = "";
                        var location = "";
                        if (moment(date).day() === 6) {
                            dateText = "Weekend of " + moment(date).format('M/D')
                        } else {
                            dateText = " Weekday " + moment(date).format('M/D') + " " + moment(date).format('ddd')
                        }
                        dateText += " - "

                        if (locations.includes("West Roxbury") && locations.includes("Walpole")) {
                            location += "WEST ROXBURY AND WALPOLE LOCATION"
                        }
                        else if (locations.includes("West Roxbury")) {
                            location += "WEST ROXBURY LOCATION"
                        }
                        else if (locations.includes("Walpole")) {
                            location += "WALPOLE LOCATION"
                        }
                        locationDates.push({
                            datetext: dateText,
                            location: location
                        });

                    })

                    that.setState({ avaliableDates: avaliableDates, roadTestDates: data, locationDates })

                }
            });
    }
    // React components are simple functions that take in props and state, and render HTML
    render() {
        return (

            <div className="holder">
                <h2>Boston Road Tests</h2>
                <h4 className="subBorder">Take Your Road Test in Boston at Parkway Driving School</h4>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="img-fluid d-block mx-auto"
                            alt="900x500"
                            src={process.env.PUBLIC_URL + '/photo1.jpg'}
                        />
                        <Carousel.Caption>
                            <span className="carouselTitle">Schedule your road test today</span>
                            <p className="carouselSubtitle">We will take care of everything for you</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="img-fluid d-block mx-auto"

                            alt="900x500"
                            src={process.env.PUBLIC_URL + '/photo2.jpg'}
                        />
                        <Carousel.Caption>
                            <span className="carouselTitle">Get Prepped for your Road Test</span>
                            <p className="carouselSubtitle">With our Road Test with One Lesson Package</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="img-fluid d-block mx-auto"

                            alt="900x500"
                            src={process.env.PUBLIC_URL + '/photo3.jpg'}
                        />
                        <Carousel.Caption>
                            <span className="carouselTitle">Fast, easy service!</span>
                            <p className="carouselSubtitle">Why wait months for the RMV, we will schedule in just a few weeks!</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <h5 className="carouselUnder">We offer road tests in the Boston, MA area!</h5>
                <div>
                    <div className="homeDescription">
                        Getting your road test requirement out of the way can be a stressful event, but it doesn't have to be.
                        With our convenient road test dates and online scheduler, you can easily be on the road.
                        <br></br>
                        <p>We offer the following options:</p>
                        <p className="warn">(All prices do not include RMV fees, which are separate. $50 - License Fee, $35 - Road Test Fee)</p>
                        <p strong className="refundwarn">Note that there are NO Refunds or cancellations for a road test for any reason including illness, emergency or rejection by the RMV examiner.</p>
                        <span>
                            Not a Parkway Driving School student? Not a problem. Pricing and registration remains the same.
                        </span>
                    </div>
                    <br></br>
                    <br></br>

                    <div className='center'>
                        <Alert variant="warning">
                            <Alert.Heading>Current Road Test Availability Dates</Alert.Heading>
                            <p>
                                These dates are subject to changes at the discretion of the RMV. We cannot guarantee any dates at this time.

                            </p>
                            <p>
                            </p>
                            <Row>
                                <Col>
                                    {this.state.locationDates.length > 0 ?
                                        <div>

                                            {this.state.locationDates.map((datedata, key) => {
                                                return <div key={key}>{datedata.datetext}  <span className="warn">{datedata.location}</span></div>
                                            })}
                                        </div>
                                        : "No Road Tests Currently Avaliable"}
                                </Col>
                            </Row>
                        </Alert>

                    </div>
                    <br></br>
                    {this.state.packages.length > 0 ? <Row>
                        <Col>
                            <div className="card card-body bg-light">
                                Package: Road Test Only $({this.state.packages.filter(x => x.package === "Road Test Only")[0].price})
                                <p>We'll take care of everything for you, except the Road Test by:</p>
                                <ul>
                                    <li>Scheduling an appointment on a date convenient for you.</li>
                                    <li>Having our trained sponsor meet you on-site. </li>
                                    <li>Providing a easily manueaverable Road Test ready Kia Soul</li>
                                </ul>
                            </div>
                        </Col>

                        <Col>
                            <div className="card card-body bg-light">
                                Package: Road Test w/ One Lesson $({this.state.packages.filter(x => x.package === "Road Test w/ One Lesson")[0].price})

                                <p>You will have all the benefits of the Road Test Only package as well as increasing your chances of passing with a 45-minute private lesson with Parkway Driving School</p>
                                <p>This lesson will cover:</p>
                                <ul>
                                    <li>Taking a simulated road test.</li>
                                    <li>Going over common road test scenarios.</li>
                                    <li>Using the vehicle you'll be using for the Road Test.</li>
                                </ul>
                            </div>
                        </Col>

                    </Row> : null}

                    <br></br>
                    <div className='center'>
                        <Link to="/registration" className="btn btn-primary full">
                            Register for Road Test
                        </Link>
                    </div>
                    <br></br>

                    <div className="footer">
                        <Row>
                            <Col className='center'>
                                <div><strong>Phone: </strong> (617) 477-4257</div>
                                <div><strong>Text Line Only: </strong> (617) 564-3803</div>
                                <div><strong>Email: </strong><a href="mailto:info@parkwaydrivingschool.com">info@parkwaydrivingschool.com</a></div>
                                <div><strong>Address: </strong>1752 Centre St, West Roxbury, MA 02132</div>

                                <br></br>
                            </Col>
                        </Row>
                        <br></br>
                    </div>

                    <br></br>
                </div>

            </div >
        );
    }
}
