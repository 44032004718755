import React from 'react';
import { Button, Table, Alert } from 'react-bootstrap'
import { Auth, API } from "aws-amplify";

import './SetRoadPackages.css';

export default class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
        this.state = {
            locations: [],
            loading: false,
            values: {},
            errors: {},
            touched: {}
        };
    }


    fetchRoadTestLocations(){
        const that = this;
        fetch('https://s3.amazonaws.com/publicdates/roadTestLocations.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                console.log(data)
                if (typeof (data) === "undefined") {
                    that.setState({ locations: [] })
                }
                else {
                    console.log(data)
                    that.setState({ locations: data })
                }
            });
    }

    componentDidMount() {
        this.fetchRoadTestLocations();
    }

    onChange(e, index, field) {
        console.log(e, index, field)
        var localPackages = this.state.locations
        switch (field) {
            case "name":
                localPackages[index].package = e
                break;
            case "price":
                localPackages[index].price = e
                break;
            case "lessons":
                localPackages[index].lessons = e
                break;
            default:
                console.warn("Default value hit. Should not reach here.")
        }
        console.log(localPackages)
        this.setState({ locations: localPackages })
    }

    deletePackage = (index) => {
        var localPackages = this.state.locations
        localPackages.splice(index, 1)
        this.setState({ locations: localPackages, refreshStatus: "", packageError: "" })
    }

    handleErrors = (response) => {
        if (!response.ok) {
            this.setState({ loading: false, packageError: "There was an error: " + response.statusText })
        }
        return response;
    }

    handleSubmit = async event => {
        this.setState({ loading: true, refreshStatus: "", packageError: "" })
        let locations = this.state.locations.filter(x => x.package.length > 0).sort((a, b) => (a.package > b.package) ? 1 : ((b.package > a.locations) ? -1 : 0))
        const that = this;
        let session = await Auth.currentSession();
        let options = {
            body: locations,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        }
        API.post("APIGateway", "/updateroadtestdates", options)
            .then(function (data) {
                that.setState({ loading: false, refreshStatus: "Successfully updated!" })
                console.log("Received the following from the API")
                console.log(data)
            }).catch(function (error) {
                console.log(error)
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
    render() {
        return (
            <div className="holder">
                {this.state.packageError ? <Alert variant="warning">
                    <strong>{this.state.packageError}</strong>
                </Alert> : null}
                {this.state.refreshStatus ? <Alert variant="success">
                    <strong>{this.state.refreshStatus}</strong>
                </Alert> : null}
                <div>
                    <h3>Active Road Test Locations</h3>
                    <div>This is not currently used for anything.</div>
                    <br></br>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Location Name</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.locations ?
                                this.state.locations.map((x, index) => {
                                    var name = x.name
                                    var address = [x.address, x.city, x.state, x.zipcode].join(" ")
                                    
                                    var disabled = !x.editName || this.state.loading;
                                    return (
                                        <tr key={index}>
                                            <td><input className="inputWidth" type='text' disabled={disabled} onChange={e => this.onChange(e.target.value, index, "name")} value={name} /></td>
                                            <td><input className="inputWidth" type='text' disabled={disabled} onChange={e => this.onChange(e.target.value, index, "address")} value={address} /></td>
                                            <td><Button variant="danger" disabled={disabled} onClick={() => this.deletePackage(index)}>Delete</Button></td>
                                        </tr>
                                    )
                                })
                                : null}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}
