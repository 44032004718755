import React from "react";
import { API, Auth } from "aws-amplify";
import classnames from 'classnames';
import { Field, Formik } from "formik";
import moment, { formatDate, parseDate } from 'moment';
import { Alert, Badge, Button, Table } from 'react-bootstrap';
import DayPicker, { DateUtils } from 'react-day-picker';
import './RegistrationForm.css';

const InputFeedback = ({ error }) =>
    error ? <div className="input-feedback">{error}</div> : null;

// Radio input
const RadioButton = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    className,
    ...props
}) => {
    return (
        <div>
            <input
                name={name}
                id={id}
                type="radio"
                value={id} // could be something else for output?
                checked={id === value}
                onChange={onChange}
                onBlur={onBlur}
                className={classnames("radio-button")}
                {...props}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};


// Radio group
const RadioButtonGroup = ({
    value,
    error,
    touched,
    id,
    label,
    className,
    children
}) => {
    const classes = classnames(
        "input-field",
        {
            "is-success": value || (!error && touched), // handle prefilled or user-filled
            "is-error": !!error
        },
        className
    );
    return (
        <div className={classes}>
            <fieldset>
                <legend>{label}</legend>
                {children}
                {touched && <InputFeedback error={error} />}
            </fieldset>
        </div>
    );
};

// Radio input
export default class RoadTestDates extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDays: [],
            loading: false,
            showToast: false,
            toastType: "Warning",
            toastMesssage: "",
            roadTestDates: {}
        };
    }

    componentDidMount() {
        const that = this;
        fetch('https://s3.amazonaws.com/publicdates/roadTestDates.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                data = JSON.parse(data)
                if (data !== null) {
                    var selectedDays = Object.keys(data).map(x => new Date(x))
                    that.setState({ selectedDays: selectedDays, roadTestDates: data })
                }

            });
    }

    handleSubmit = async event => {
        this.setState({ showToast: false, toastMesssage: "" })
        const that = this
        let newRoadTestDates = {}
        let roadTestDates = this.state.roadTestDates;
        let selectedDays = this.state.selectedDays.map(x => x.toISOString())
        let newValidDates = Object.keys(roadTestDates).filter(x => selectedDays.includes(x))
        newValidDates.forEach(date => {
            newRoadTestDates[date] = roadTestDates[date]
        });

        let session = await Auth.currentSession();
        let options = {
            body: newRoadTestDates,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        }
        this.setState({ loading: true })
        API.post("APIGateway", "/updateroadtestdates", options)
            .then(function (data) {
                that.setState({ loading: false, showToast: true, toastType: "Success", toastMesssage: "Successfully updated!" })
            }).catch(function (error) {
                console.log(error)
                if (error.response.status === 401) {
                    that.setState({ loading: false, showToast: true, toastType: "Warning", toastMesssage: "Authentication Failed. Please log back in." })
                }
                else {
                    that.setState({ loading: false, showToast: true, toastType: "Warning", toastMesssage: "There was an error saving your dates. Please copy this message and send it to your developer for debugging." + JSON.stringify(error.response) })
                }
            });;
    }

    addLocation = (date, location) => {
        var roadTestDates = this.state.roadTestDates
        if (!roadTestDates[date]) {
            roadTestDates[date] = {}
        }
        if (!roadTestDates[date].locations) {
            roadTestDates[date].locations = []
        }
        roadTestDates[date].locations.push(location)
        roadTestDates[date].locations = roadTestDates[date].locations.sort()

        this.setState({ roadTestDates })
    }

    subtractLocation = (date, location) => {
        var roadTestDates = this.state.roadTestDates
        console.log(date)
        roadTestDates[date].locations = roadTestDates[date].locations.filter(x => x !== location)
        if (roadTestDates[date].locations && roadTestDates[date].locations.length == 0) {
            delete roadTestDates[date];
        }
        this.setState({ roadTestDates })
    }


    handleDayClick(day, { selected }) {
        this.setState({ refreshStatus: "", dateError: "" })

        const { selectedDays } = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            let dayOfWeek = moment(day).day()
            if (dayOfWeek === 0) { //Drop sunday to saturday
                day = moment(day).subtract(1, "day").startOf('day').toDate();
            }
            if (dayOfWeek >= 2 && dayOfWeek <= 5) {
                day = moment(day).startOf('isoWeek').toDate();
            }

            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            if (selectedIndex === -1) {
                day = moment(day).startOf('day').toDate();
                selectedDays.push(day);
            }
        }
        this.setState({ selectedDays });
    }
    render() {
        const { showToast, toastMesssage, toastType } = this.state;

        return (
            <div className="holder">
                {showToast ?
                    <div>
                        {toastType === "Warning" ?
                            <Alert variant={"warning"} onClose={() => this.setState({ showToast: false })} dismissible style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                zIndex: 1,
                                width: "40%"
                            }}>
                                <Alert.Heading>{toastType}</Alert.Heading>
                                {toastMesssage}
                            </Alert> : null}
                        {toastType === "Success" ?
                            <Alert variant={"success"} onClose={() => this.setState({ showToast: false })} dismissible style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                zIndex: 1,
                                width: "40%"
                            }}>
                                <Alert.Heading>{toastType}</Alert.Heading>
                                {toastMesssage}
                            </Alert> : null}

                    </div> : null}
                <h3>Select Active Dates</h3>
                <DayPicker
                    selectedDays={this.state.selectedDays}
                    onDayClick={this.handleDayClick}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    numberOfMonths={2}
                />


                <div>
                    Select Location of Each Date

                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Locations</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.selectedDays ?
                                this.state.selectedDays
                                    .sort(function (a, b) { return a - b })
                                    .map((date, index) => {
                                        var parsedDate = date.toISOString()
                                        var longDate = moment(date).format("ddd, MMMM Do YYYY");
                                        var dayOfWeek = moment(date).day();
                                        var locations = [];
                                        if (this.state.roadTestDates[parsedDate] && this.state.roadTestDates[parsedDate].locations) {
                                            locations = this.state.roadTestDates[parsedDate].locations
                                        }

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {longDate}
                                                </td>
                                                <td>
                                                    {dayOfWeek === 6 ?
                                                        <Badge pill variant="primary" key={index}>
                                                            {"Weekend"}
                                                        </Badge> :
                                                        <Badge pill variant="primary" key={index}>
                                                            {"Week"}
                                                        </Badge>}
                                                </td>
                                                <td>
                                                    {locations.map((location, index) => {
                                                        return (
                                                            <Badge pill variant="primary" key={index + location}>
                                                                {location}
                                                            </Badge>
                                                        )
                                                    })}
                                                </td>

                                                <td>
                                                    {
                                                        locations.includes("West Roxbury") ?
                                                            <Button size="sm" variant="danger" disabled={!locations.includes("West Roxbury")} onClick={() => this.subtractLocation(parsedDate, "West Roxbury")}>- West Roxbury</Button>
                                                            :
                                                            <Button size="sm" variant="success" disabled={locations.includes("West Roxbury")} onClick={() => this.addLocation(parsedDate, "West Roxbury")}>+ West Roxbury</Button>
                                                    }
                                                    {
                                                        locations.includes("Walpole") ?
                                                            <Button size="sm" variant="danger" disabled={!locations.includes("Walpole")} onClick={() => this.subtractLocation(parsedDate, "Walpole")}>- Walpole</Button>
                                                            :
                                                            <Button size="sm" variant="success" disabled={locations.includes("Walpole")} onClick={() => this.addLocation(parsedDate, "Walpole")}>+ Walpole</Button>

                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                : null}
                        </tbody>
                    </Table>
                </div>

                <div>
                    <Formik
                        ref={this.formikRef}
                        initialValues={{

                        }}
                        onSubmit={this.bindSubmitForm}

                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched,
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Button variant="primary" disabled={this.state.loading} onClick={this.handleSubmit}>Save Dates</Button>
                                    <hr></hr>
                                    <h3>Form Preview</h3>
                                    <p>Note: This matches the form, which hides dates that are within 12 days of the booking.</p>

                                    <p>If the number of slots for the date are filled, please disable the date.</p>
                                    <p>Note: The week starts on a Saturday.</p>

                                    <RadioButtonGroup
                                        id="roadTestDateOption"
                                        label="Preferred Date (Subject to RMV availability)"
                                        value={values.roadTestDateOption}
                                        error={errors.roadTestDateOption}
                                        touched={touched.roadTestDateOption}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                    >
                                        {this.state.selectedDays.filter(x => moment(x).add(5, 'hours').subtract(12, 'days').isAfter(moment()))
                                            .sort(function (a, b) { return a - b })
                                            .map((roadTestDate) => {
                                                var parsedDate = roadTestDate.toISOString()
                                                var locations = [];
                                                if (this.state.roadTestDates[parsedDate] && this.state.roadTestDates[parsedDate].locations) {
                                                    locations = this.state.roadTestDates[parsedDate].locations
                                                }
                                                if (roadTestDate.getDay() === 6) {
                                                    return (
                                                        locations.map((location, key) => {
                                                            return (
                                                                <Field
                                                                    key={roadTestDate + location + key}
                                                                    component={RadioButton}
                                                                    name="roadTestDateOption"
                                                                    id={roadTestDate.toISOString()}
                                                                    label={" Weekend of " + moment(roadTestDate).format('M/D') + " (Sat-Sun) at " + location}
                                                                />
                                                            )
                                                        })
                                                    )

                                                }
                                                else {
                                                    return (
                                                        locations.map((location, key) => {
                                                            return (
                                                                <Field
                                                                    key={roadTestDate + location + key}
                                                                    component={RadioButton}
                                                                    name="roadTestDateOption"
                                                                    id={roadTestDate.toISOString()}
                                                                    label={"Week of " + moment(roadTestDate).subtract(2, "day").format('M/D') + " - " + moment(roadTestDate).add(4, "day").format('M/D') + " (Sat-Fri) at " + location + " (Exact date tbd)"}
                                                                />
                                                            )
                                                        })
                                                    )


                                                }
                                            })
                                        }

                                    </RadioButtonGroup>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        );
    }
}
