import { API, Auth } from "aws-amplify";
import React from "react";
import { Accordion, Alert, Card, Table } from 'react-bootstrap';
import './RegistrationForm.css';
import crypto from 'crypto-js';

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-day-picker/lib/style.css';

export default class RoadLessonViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "0",
            sortedLessons: [],
            loading: false,
            showToast: false,
            toastMessage: "",
            attemptedToLoadData: false,
            password: "ef92b778bafe771e89245b89ecbc08a44a4e166c06659911881f383d4473e94f"
        };
    }

    fetchRoadTestInstructors() {
        const that = this;
        fetch('https://s3.amazonaws.com/publicdates/roadTestInstructors.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(this.handleErrors)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                if (typeof (data) === "undefined") {
                    that.setState({ instructors: [] })
                }
                else {
                    var decryptedInstructors = []
                    for (let i = 0; i < data.length; i++) {
                        let instructor = data[i];
                        var decryptedName = crypto.AES.decrypt(instructor.resourceTitle, that.state.password).toString(crypto.enc.Utf8)
                        decryptedInstructors.push(
                            { resourceId: instructor.resourceId, resourceTitle: decryptedName, value: decryptedName, label: decryptedName }
                        )
                    }
                    that.setState({ instructors: decryptedInstructors })
                }
            });

    }

    componentDidMount() {
        //We should fetch the instructors and save their state here.
        this.fetchRoadTestInstructors();
        this.handleGetFutureLessons();

    }

    handleGetFutureLessons = async () => {
        this.setState({ loading: true })
        const that = this
        let session = await Auth.currentSession();
        let options = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        }
        try {
            let data = await API.get("APIGateway", "/getallfuturelessons", options)
            var unsortedLessons = []
            for (var i = 0, l = data.length; i < l; i++) {
                var lesson = data[i];
                lesson["sortString"] = lesson.date + lesson.startTime;
                unsortedLessons.push(lesson);
            }
            var sortedLessons = unsortedLessons.sort((a, b) => String(a.sortString).localeCompare(b.sortString));
            that.setState({ sortedLessons: sortedLessons, loading: false, refreshStatus: "Successfully updated!", attemptedToLoadData: true })
        }
        catch (error) {
            console.log(error)
            if (error.response) {
                if (error.response.status === 401) {
                    that.setState({ loading: false, attemptedToLoadData: true, dateError: "Authentication Failed. Please log back in." })
                }
                else {
                    that.setState({ loading: false, attemptedToLoadData: true, dateError: "There was an error saving your dates. Please copy this message and send it to your developer for debugging." + JSON.stringify(error.response) })
                }
            }
            else {
                that.setState({ loading: false, attemptedToLoadData: true, dateError: "Network error." })
            }

            return ([])
        }

    }

    renderRow(slot, index) {
        let students = []
        let bookedInstructors = [];

        for (const instructor in slot.bookedStudents) {
            bookedInstructors.push(instructor)
            if (slot.bookedStudents[instructor].hasOwnProperty("name")) {
                students.push({
                    "date": slot.date,
                    "slotName": slot.slotName,
                    "name": slot.bookedStudents[instructor].name,
                    "phone": slot.bookedStudents[instructor].phone,
                    "instructor": instructor
                })
            }
        }
        for (const instructor in slot.selectedInstructors) {
            if (!bookedInstructors.includes(slot.selectedInstructors[instructor].label)) {
                students.push({
                    "date": slot.date,
                    "slotName": slot.slotName,
                    "name": "",
                    "phone": "",
                    "instructor": slot.selectedInstructors[instructor].label
                })
            }

        }

        return students.map(student => {
            return (<tr key={index + student.instructor} className="d-flex">
                <td className="col-2">
                    {student.date}
                </td>
                <td className="col-2">
                    {student.slotName}
                </td>
                <td className="col-2">
                    {student.name}
                </td>
                <td className="col-2">
                    {student.phone}
                </td>
                <td className="col-4">
                    {student.instructor}
                </td>
            </tr>)
        })
    }

    render() {
        const { showToast, toastType, toastMessage } = this.state;

        return (
            <div className="holder">
                <h3>View All Scheduled Road Test Lessons</h3>
                {showToast ?
                    <div>
                        {toastType === "Warning" ?
                            <Alert variant={"warning"} onClose={() => this.setState({ showToast: false })} dismissible style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                zIndex: 1,
                                width: "40%"
                            }}>
                                <Alert.Heading>{toastType}</Alert.Heading>
                                {toastMessage}
                            </Alert> : null}
                        {toastType === "Success" ?
                            <Alert variant={"success"} onClose={() => this.setState({ showToast: false })} dismissible style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                zIndex: 1,
                                width: "40%"
                            }}>
                                <Alert.Heading>{toastType}</Alert.Heading>
                                {toastMessage}
                            </Alert> : null}

                    </div> : null}



                <Accordion defaultActiveKey="0" activeKey={this.state.activeKey}>
                    <Card>
                        <Accordion eventkey="0">

                            <Card.Body>
                                {this.state.attemptedToLoadData ?

                                    <div>{this.state.sortedLessons.length > 0 ? <div>

                                        <Table striped bordered hover responsive size="sm">
                                            <thead>
                                                <tr className="d-flex">
                                                    <th className="col-2">Date</th>
                                                    <th className="col-2">Slot Time</th>
                                                    <th className="col-2">Student</th>
                                                    <th className="col-2">Phone</th>

                                                    <th className="col-4">Instructor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.sortedLessons.map((x, index) => {
                                                    return this.renderRow(x, index)
                                                })}

                                            </tbody>

                                        </Table></div> : <p>No data found</p>}</div> :



                                    <div>Please wait, loading data...</div>}


                            </Card.Body>
                        </Accordion>
                    </Card>
                </Accordion>
                <br></br>
            </div>
        );
    }
}
