import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home.jsx";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
/* import Registration from './containers/Form'
 */
import Confirmation from './containers/Confirmation'
import Admin from './containers/Admin.jsx'
import RoadTestDatePicker from './containers/SetRoadTestDates.jsx'
import RoadLessonManager from './containers/ManageRoadLessons.jsx'
import RoadLessonViewer from './containers/ViewAllRoadLessons.jsx'
import RoadLessonInstructors from './containers/SetRoadTestInstructors.jsx'


import RoadPackagePicker from './containers/SetRoadPackages.jsx'
import RoadLocationPicker from './containers/SetRoadLocations.jsx'
import ModifyStudentData from './containers/ModifyStudentData.jsx'
import studentTable from './containers/Table.jsx'
import AttendeeList from './containers/AttendeeList'
import Transactions from './containers/ExportTransactions'
import About from './containers/About.jsx'
import Contact from './containers/Contact.jsx'
import Appointment from './containers/Appointments'
import RegistrationForm from './containers/RegistrationForm'
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/about" exact component={About} props={childProps} />
    <AppliedRoute path="/contact" exact component={Contact} props={childProps} />

    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <AuthenticatedRoute path="/roadTestDates" exact component={RoadTestDatePicker} props={childProps} />
    <AuthenticatedRoute path="/roadTestPackages" exact component={RoadPackagePicker} props={childProps} />
    <AuthenticatedRoute path="/roadTestLocations" exact component={RoadLocationPicker} props={childProps} />

    <AuthenticatedRoute path="/appointments" exact component={Appointment} props={childProps} />
    <AuthenticatedRoute path="/studentTable" exact component={studentTable} props={childProps} />
    <AuthenticatedRoute path="/attendeelist" exact component={AttendeeList} props={childProps} />
    <AuthenticatedRoute path="/transactions" exact component={Transactions} props={childProps} />
    <AuthenticatedRoute path="/modify" exact component={ModifyStudentData} props={childProps} />

    <AuthenticatedRoute path="/manageRoadLessons" exact component={RoadLessonManager} props={childProps} />
    <AuthenticatedRoute path="/viewAllRoadLessons" exact component={RoadLessonViewer} props={childProps} />
    <AuthenticatedRoute path="/roadLessonInstructors" exact component={RoadLessonInstructors} props={childProps} />


    <AppliedRoute path="/confirmation" exact component={Confirmation} props={childProps} />
    <AppliedRoute path="/registration/:option?" exact component={RegistrationForm} props={childProps} />
    <AuthenticatedRoute path="/admin" exact component={Admin} props={childProps} />

    { /* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>;
