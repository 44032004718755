import React from 'react';
import { Button, Alert, Form } from 'react-bootstrap'
import { Auth, API } from "aws-amplify";
import { CSVLink } from 'react-csv';

import moment from 'moment';
import ReactTable from "react-table";
import 'react-table/react-table.css'

var headers = [
    { label: 'ID', key: 'transactionID' },
    { label: 'Date', key: 'transactionDate' },
    { label: 'Description', key: 'name' },
    { label: 'Amount', key: 'cost' }
];

export default class RoadTestDates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedData: false,
            loading: false,
            data: [],
            columns: [],
            filteredData: [],
            yearOptions: ["2018", "2019"],
            filterYear: "2019"
        };
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(e) {
        var target = e.target;
        this.setState({ filterYear: target.value })
    }

    async componentDidMount() {
        var currentYear = moment().add("1", "year").year()
        var yearOptions = []

        while (currentYear > 2017) {
            yearOptions.push(currentYear + "")
            currentYear--;
        }
        yearOptions = yearOptions.reverse()
        this.setState({ yearOptions, filterYear: moment().format("YYYY") })
    }

    getFilteredStudents = async () => {
        this.setState({ "fetchedData": true, "loading": true });
        let filter = { "date": this.state.filterYear }
        const that = this

        let session = await Auth.currentSession();

        let options = {
            body: filter,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken
            }
        }

        try {
            let data = await API.post("APIGateway", "/v2getStudentsByFilter", options);
            that.setState({ loading: false, refreshStatus: "Successfully fetched data!" })
            if (data.length === 0) {
                that.setState({ dateError: "No students found." })
                return
            }

            const actualDateAdjustment = {
                "Saturday": 0,
                "Sunday": 1,
                "Monday": 2,
                "Tuesday": 3,
                "Wednesday": 4,
                "Thursday": 5,
                "Friday": 6
            }

            data = data.map(x => {
                try {
                    if (x.selectedPackage.includes("Road Test Only")) {
                        x.scheduledLesson = "N/A"
                    }
                    else {
                        x.scheduledLesson = String(x.scheduledLesson)
                    }
                } catch (error) {
                    x.scheduledLesson = "Unknown"
                }

                //We need to convert the date
                x.date = moment(x.date).add(actualDateAdjustment[x.weekendDate], "days").format("MM-DD-YYYY (dddd)")


                return x
            })
            data = data.sort(function (a, b) { // non-anonymous as you ordered...
                return b.date < a.date ? 1 // if b should come earlier, push a to end
                    : b.date > a.date ? -1 // if b should come later, push a to begin
                        : 0;                   // a and b are equal
            });

            var filteredData = data.filter(x => x.year === that.state.filterYear)
            that.setState({ data: data, filteredData: filteredData })

            var cols = [{
                Header: 'Date',
                accessor: 'date'
            }, {
                Header: 'Notes',
                accessor: 'note'
            }, {
                Header: 'First Name',
                accessor: 'fName'
            }, {
                Header: 'Last Name',
                accessor: 'lName'
            }, {
                Header: 'Email',
                accessor: 'emailAddress'
            },
            {
                Header: 'Phone',
                accessor: 'phone'
            }, {
                Header: 'Slot',
                accessor: 'slot'
            }, {
                Header: 'Package',
                accessor: 'selectedPackage'
            }, {
                Header: 'Permit Number',
                accessor: 'permitNo'
            }, {
                Header: 'Date of Birth',
                accessor: 'dob'
            }, {
                Header: 'Payment Status',
                accessor: 'paymentStatus'
            },
            {
                Header: 'Driving School',
                accessor: 'drivingSchool'
            }, {
                Header: 'Source',
                accessor: 'source'
            }]
            that.setState({ columns: cols })
        } catch (error) {
            console.log(error)
            if (error.response.status === 401) {
                that.setState({ loading: false, dateError: "Authentication Failed. Please log back in." })
            }
            else {
                that.setState({ loading: false, dateError: "There was an error viewing the student table. Please copy this message and send it to your developer for debugging." + JSON.stringify(error.response) })

            }
        }

    }

    handleFilterDownload = async event => {
        this.setState({ loading: true })

        this.setState({ filteredData: this.reactTableTransaction.getResolvedState().sortedData, renderDownload: true }, () => {
            this.filterCSVLink.link.click()
            this.setState({ loading: false })
        })
    }
    render() {
        return (

            <div className="holder">
                <h3>Student Record Table</h3>
                {this.state.dateError ? <Alert bsStyle="warning">
                    <strong>{this.state.dateError}</strong>
                </Alert> : null}
                {this.state.refreshStatus ? <Alert bsStyle="success">
                    <strong>{this.state.refreshStatus}</strong>
                </Alert> : null}
                <div>
                    <Form.Group controlId="formControlsSelect">
                        <Form.Label>Select Year to Download</Form.Label>
                        <Form.Control
                            name="selectedYear"
                            as="select"
                            onChange={this.handleChange}
                            value={this.state.filterYear}
                        >
                            {this.state.yearOptions.map(year => {
                                return (
                                    <option key={year} value={year}>{year}</option>
                                )

                            })}
                        </Form.Control>
                    </Form.Group>
                    <Button bsStyle="primary" disabled={this.state.loading || this.state.filterYear.length === 0} onClick={this.getFilteredStudents}>Fetch students for selected year</Button>
                    <br></br>
                </div>

                {this.state.fetchedData ? <div>
                    {this.state.data.length > 0 ?
                        <div>
                            <br></br>
                            <Button bsStyle="primary" disabled={this.state.loading || this.state.data.length === 0} onClick={this.handleFilterDownload}>Download Table Data</Button>
                            <p>Note: This applies any filters you specified from the table above.</p>
                            <CSVLink ref={(r) => this.filterCSVLink = r} data={this.state.data} headers={headers} className="hidden" filename={this.state.filterYear + "parkwaydrivingschoolexport.csv"}></CSVLink>

                            <ReactTable
                                ref={(r) => this.reactTableTransaction = r}
                                defaultSorted={[
                                    {
                                        id: "transactionDate",
                                        desc: false
                                    }
                                ]}
                                data={this.state.data}
                                columns={this.state.columns}
                                minRows={0}
                                defaultPageSize={250}
                                pageSizeOptions={[5, 10, 20, 25, 50, 100, 250]}
                            />
                            <div>
                                <br></br>
                                <br></br>
                                <Button bsStyle="primary" disabled={this.state.loading || this.state.filteredData.length === 0} onClick={this.handleFilterDownload}>Download Table Data</Button>
                                <p>Note: This applies any filters you specified from the table above.</p>
                                <CSVLink ref={(r) => this.filterCSVLink = r} data={this.state.data} headers={headers} className="hidden" filename={this.state.filterYear + "parkwaydrivingschoolexport.csv"}></CSVLink>
                            </div>

                        </div>
                        : <div> {this.state.loading ? "Please wait, records loading " : "No records found."}</div>}
                </div> : <div>Please fetch data to see records.</div>}
            </div>
        );
    }
}
