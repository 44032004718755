import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import logo from './logo.png';
import Routes from "./Routes";
import './App.css';

require('core-js');
require('es6-promise').polyfill();
require('isomorphic-fetch');

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    }
    catch (e) {
      if (e !== 'No current user') {
        //alert(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      <div className="AppContainer">
        <Navbar bg="light" expand="sm">
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="198.5"
              height="100"
              alt="Parkway Driving School Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">

            </Nav>
            {this.state.isAuthenticated ?
              <Nav className="justify-content-end" >
                <Nav.Link>
                  <LinkContainer exact to="/admin">
                    <NavItem>Admin</NavItem>
                  </LinkContainer>
                </Nav.Link>
                <Nav.Link>
                  <LinkContainer exact to="/registration">
                    <NavItem>
                      Registration
                      </NavItem>
                  </LinkContainer>
                </Nav.Link>
                <Nav.Link>
                  <LinkContainer exact to="/about">
                    <NavItem>
                      About
                      </NavItem>
                  </LinkContainer>
                </Nav.Link>
                <Nav.Link>
                  <LinkContainer exact to="/contact">
                    <NavItem>
                      Contact
                      </NavItem>
                  </LinkContainer>
                </Nav.Link>
                <Nav.Link>
                  <LinkContainer to="/login">
                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                  </LinkContainer>

                </Nav.Link>

              </Nav>

              :
              <Nav className="justify-content-end" >
                <Nav.Link>
                  <LinkContainer exact to="/">
                    <NavItem>
                      Home
                      </NavItem>
                  </LinkContainer>
                </Nav.Link>
                <Nav.Link>
                  <LinkContainer exact to="/registration">
                    <NavItem>
                      Registration
                      </NavItem>
                  </LinkContainer>
                </Nav.Link>
                <Nav.Link>
                  <LinkContainer exact to="/about">
                    <NavItem>
                      About
                      </NavItem>
                  </LinkContainer>
                </Nav.Link>
                <Nav.Link>
                  <LinkContainer exact to="/contact">
                    <NavItem>
                      Contact
                      </NavItem>
                  </LinkContainer>
                </Nav.Link>
              </Nav>
            }

          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div >
    );
  }
}

export default withRouter(App);
